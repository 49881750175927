import { toURLSearchParams } from '@/helpers/apiHelper'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { createToastInterface, POSITION } from 'vue-toastification'
import jwtDefaultConfig from './jwtDefaultConfig'
import { fetchNewAuthToken } from '@/helpers/auth'

const toast = createToastInterface({
  position: POSITION.BOTTOM_RIGHT,
  hideProgressBar: true,
  closeOnClick: false,
  closeButton: false,
  timeout: 4000,
  transition: 'Vue-Toastification__fade',
})

export default class JwtService {
  // Will be used by this service for making API calls
  API = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(API, jwtOverrideConfig) {
    this.API = API
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.API.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.API.interceptors.response.use(
      response => response,
      error => {
        const { config, response } = error
        const originalRequest = config

        if (response && response.status === 401 && [
          '/oauth2/token',
          '/sign_out',
        ].includes(config.url) === false) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.access_token)
              localStorage.setItem('token_expiration', r.data.expires_in)
              this.setRefreshToken(r.data.refresh_token)
              this.onAccessTokenFetched(r.data.access_token)
            }).catch(err => {
              // if (err.response.status === 401) {
                // fetchNewAuthToken()
                localStorage.removeItem('access_token')
                localStorage.removeItem('token_expiration')
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')
                window.location.href = '/login'
              // }
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.API(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  // eslint-disable-next-line class-methods-use-this
  showToast(title) {
    toast({
      component: ToastificationContent,
      props: {
        title,
        variant: 'danger',
      },
    })
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.API.post(this.jwtConfig.loginEndpoint, ...args)
  }

  async logout() {
    const { protocol, host } = window.location
    await this.API.post(this.jwtConfig.logoutEndpoint, toURLSearchParams({
      url: `${protocol}//${host}/login`,
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    window.location.href = `${this.API.defaults.baseURL}/logout?url=${protocol}//${host}/login`
  }

  register(...args) {
    return this.API.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.API.post(this.jwtConfig.refreshEndpoint, toURLSearchParams({
      grant_type: 'refresh_token',
      client_id: process.env.VUE_APP_PROZESS_CLIENT_ID,
      client_secret: process.env.VUE_APP_PROZESS_CLIENT_SECRET,
      refresh_token: this.getRefreshToken(),
    }), {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).catch(() => {
      localStorage.clear()
      location.href = '/login'
    })
  }
}
