export function defaultBanner() {
  return {
    error: null,
    file: null,
    src: null,
  }
}

export function defaultForm() {
  return {
    submitLabelColorCode: '#ffffff',
    submitButtonColorCode: process.env.VUE_APP_COLOR_PRIMARY,
    submitLabelText: 'Submit',
    title: '',
    description: '',
    acceptingResponse: true,
    open: false,
  }
}

export function defaultThankYouForm() {
  return {
    thankYouPageTitle: '',
    thankYouPageParagraph: '',
  }
}

export const defaultStateFactory = () => ({
  resource: null,
  entities: [],
  selectedEntities: [],
  fieldOptions: [],
  errors: [],
  banner: { ...defaultBanner() },
  form: { ...defaultForm() },
  fieldInputs: [],
  lastSavedFieldInputs: [],
  thankYouForm: { ...defaultThankYouForm() },
  removeBannerImage: false,
})

export default () => (defaultStateFactory())
