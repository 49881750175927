export default {
  selectedWidget: null,
  columns: [],
  widgetValue: {
    type: '',
    description: '',
  },
  previewData: '',
  tableData: [],
  isSaved: false,
  isPreviewing: false,
  baseData: {},
  chartConfig: {},
  errors: [],
  id: null,
  userFilterValues: undefined,
  widgetConfiguration: undefined,
}
