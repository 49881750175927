import API from '@axios'
import { v4 as uuidv4 } from 'uuid'

export const fetchNewAuthToken = async code => {
  const { protocol, host } = window.location
  const frontendUrl = `${protocol}//${host}`
  const authCode = code || localStorage.code

  const params = new URLSearchParams()
  params.append('grant_type', 'authorization_code')
  params.append('code', authCode)
  params.append('redirect_uri', `${frontendUrl}/oauth2-redirect`)
  params.append('code_verifier', localStorage.getItem('code_verifier'))
  params.append('client_id', process.env.VUE_APP_PROZESS_CLIENT_ID)
  params.append('client_secret', process.env.VUE_APP_PROZESS_CLIENT_SECRET)

  const res = await API.post('/oauth2/token', params, {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-request-uuid': uuidv4(),
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })

  localStorage.setItem('access_token', res.data.access_token)
  localStorage.setItem('refresh_token', res.data.refresh_token)
  localStorage.setItem('token_expiration', res.data.expires_in)
}
