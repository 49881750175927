<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component
      :is="layout"
      v-if="permissionsLoaded === true"
    >
      <router-view />
    </component>
    <p
      v-else
      class="m-1"
    >
      Loading...
    </p>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
// import { provideToast } from 'vue-toastification/composition'
import { ref, watch } from 'vue'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import appInitialDataMixin from './mixins/appInitialData'
import useJwt from './auth/jwt/useJwt'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
  },
  mixins: [appInitialDataMixin],
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    // provideToast({
    //   hideProgressBar: true,
    //   closeOnClick: false,
    //   closeButton: false,
    //   icon: false,
    //   timeout: 3000,
    //   transition: 'Vue-Toastification__fade',
    // })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const windowWidth = ref(useWindowSize().width)

    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
  data() {
    return {
      permissionsLoaded: false,
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      if (!useJwt.getToken()) return null
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  created() {
    this.getAppInitialData().finally(() => {
      this.permissionsLoaded = true
    })
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  mounted() {
    this.$root.historyCount = window.history.length
    useJwt.API.interceptors.response.use(
      response => response,
      error => {
        const { response } = error
        let message = ''
        const exception = this.$lodash.get(response, 'data.exception')

        if (exception) {
          const exceptionMessage = exception.split('.')
          message = exceptionMessage[exceptionMessage.length - 1]

          const allowedMessage = [
            'MissingServletRequestPartException',
            'EmployeeUserServiceOperationException',
            'ConstraintViolationException',
            'AttachmentServiceOperationException',
            'NoteAttachmentServiceOperationException',
            'DigitalSignatureOperationException',
            'DuplicateFieldException',
            'CannotDeleteMetaStatusException',
            'ComponentToBeRemovedInUseException',
          ]

          const alternativeMessage = {
            SignatureCannotBeDeletedException: 'This signature request cannot be deleted',
            CrmRelationshipIsCurrentlyUsingException: 'Failed to delete. This relationship is currently in use.',
            DataAlreadyConnectedException: 'Connection already exists.',
            MethodArgumentNotValidException: '',
            MaxUploadSizeExceededException: 'Max upload size exceeded',
            FieldInUseException: 'Field in use',
            MobileNumberNotPresentException: 'Selected admin dont have mobile number',
            DocumentWithDigitalSignatureRecordException: 'Action not permitted. This document has signature request.',
            EntityAlreadyExistingException: 'Entity name already exist',
            FieldKeyAlreadyExistingException: 'Field key already exist',
            NoColumnsRemainingException: 'Custom entity should have at least 1 custom field',
            ColumnHasValueException: 'This custom entity already have data',
            EmployeeUserAlreadyActiveException: 'User already exists',
            DatasourceValidationException: 'No Results Found',
            InvalidMaxCharacterCountException: 'New max character count is less than to current value.',
            DuplicateValueException: () =>
              response.data.message.split('Detail: Key')[1].split(')=(')[1].replace(')', '').replace('already exists.', this.$t('already exist')),
            AnotherConnectionAlreadyExistingException: 'Selected relationship is already connected to another record.',
            ConnectionAlreadyExistingException: 'Selected relationship is already connected to another record.',
            CannotCreateUniquenessException: 'Sorry, unique and required is not possible for this field',
            DuplicateKeyException: () =>
              response.data.message
                .split('Detail: Key')[1]
                .split('.; nested')[0]
                .split(')=(')[1]
                .replace(')', '')
                .replace('already exists', `${this.$t('already exist')}`),
            DatasourceInUseException: 'Error saving changes on the Datasource. The target Datasource is currently being used by Widget/s.',
            EmptyDashboardException: 'This dashboard is currently empty.',
          }

          let serverSideMessage = null
          if (allowedMessage.includes(message)) {
            serverSideMessage = response.data.message
          }

          const fallbackMessage = response.config.method !== 'get' ? "Oops! Something went wrong on our end. We'll fix this as soon as possible." : null

          message =
            serverSideMessage ||
            (typeof alternativeMessage[message] === 'function' ? alternativeMessage[message]() : alternativeMessage[message]) ||
            fallbackMessage

          // Dont display form error
        } else if (['put', 'post'].includes(response.config.method) && response.status === 403) {
          message = this.$t('Operation not permitted')
        }

        if (message) {
          this.$swal({
            title: this.$t(message),
            icon: 'warning',
            confirmButtonText: this.$t('Close'),
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
            allowOutsideClick: false,
          })
        }

        return Promise.reject(error)
      },
    )
  },
}
</script>
