export const Relationship = {
  ONE_TO_ONE: '1to1',
  ONE_TO_MANY: '1toN',
  MANY_TO_ONE: 'Nto1',
  MANY_TO_MANY: 'NtoM',
}

export const RelationshipTypes = {
  'one to one': Relationship.ONE_TO_ONE,
  'one to many': Relationship.ONE_TO_MANY,
  'many to one': Relationship.MANY_TO_ONE,
  'many to many': Relationship.MANY_TO_MANY,
}

export const RelationshipFilter = {
  X_TO_ONE: 'xTo1',
  X_TO_MANY: 'xToN',
}
