<template>
  <div class="prozess-company-contact__item-field">
    <div>{{ item.label }}</div>
    <div v-if="item.fieldType === CustomFieldTypes.Date">
      {{ item.value ? $d(new Date(item.value), 'short') : '' }}
    </div>
    <div v-else-if="item.fieldType === CustomFieldTypes.Number">
      {{ $n(item.value) }}
    </div>
    <div v-else-if="item.fieldType === CustomFieldTypes.Percent">
      {{ $n(item.value * 100) }}%
    </div>
    <div v-else-if="item.fieldType === CustomFieldTypes.MultiSelect">
      {{ advancedFieldValue(item.value) }}
    </div>
    <div v-else style="max-width: 200px">
      {{ item.value }}
    </div>
  </div>
</template>

<script>
import { advancedFieldValue } from '@/helpers/customFields'
import CustomFieldTypes from '@/constants/customFieldTypes'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      CustomFieldTypes,
    }
  },

  methods: {
    advancedFieldValue,
  },
}
</script>
