import state from '@/store/advanced-search/state'
import mutations from '@/store/advanced-search/mutations'
import getters from '@/store/advanced-search/getters'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
