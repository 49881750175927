<template>
  <div
    class="prozess-input w-100"
    :class="{
      'prozess-input__disabled': $attrs.disabled === '',
    }"
  >
    <b-form-textarea
      :id="id"
      v-model="currentValue"
      v-bind="{ ...$props, ...$attrs }"
      :disabled="$attrs.disabled"
      @input="handleInput"
    />
    <label
      :for="id"
      class="input__label label--indented"
      :class="{ 'input__label_focus': showLabel || !!currentValue }"
    >
      {{ $attrs.placeholder }}
    </label>
  </div>
</template>

<script>

export default {
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      default: null,
    },

    appendToBody: {
      type: Boolean,
      default: true,
    },

    hint: {
      type: String,
      default: null,
    },

    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentValue: null,
      showLabel: false,
    }
  },
  computed: {
    id() {
      return (
        this.$attrs.id
        || this.$case.snake(`${this.$attrs.placeholder} ${this.$randStr(4)}`)
      )
    },
  },
  watch: {
    value: {
      handler() {
        this.currentValue = this.value
      },
      immediate: true,
    },
  },
  methods: {
    handleInput() {
      this.$emit('input', this.currentValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.prozess-input {
  &__disabled,
  &[disabled='disabled'] {
    div.input-group > div.input-group-prepend {
      opacity: 0.5;
      div.input-group-text {
        margin-right: 1px;
      }
    }
  }

  textarea::placeholder {
    color: transparent !important;
  }

  .input__label {
    position: absolute;
    top: 0.72rem;
    left: 1rem;
    font-size: 11px;
    color: var(--colour--input-placeholder);
    transition: all 0.15s ease-in-out;

    &.label--indented {
      left: 3rem;
    }
  }

  input:focus,
  input:not(:placeholder-shown) {
   .input__label {
    top: -1.45rem;
    left: 0;
    font-size: 10px;
    color: var(--colour--text-primary);
  }
}
}

.input__label_focus {
  top: -1.45rem !important;
  left: 0!important;
  font-size: 10px;
  color: var(--colour--text-primary)!important;

}
</style>
