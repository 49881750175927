<template>
  <div
    class="w-100"
    :class="{
      'prozess-input__disabled': $attrs.disabled === '',
      'prozess-input': !isBirthDate,
    }"
  >
    <prozess-input
      v-if="isBirthDate"
      v-model="currentValue"
      style="padding-top: 0px !important"
      type="date"
      format="YYYY-MM-DD"
      @input="handleInput"
    />
    <b-form-datepicker
      v-else
      v-model="currentValue"
      :disabled="$attrs.disabled"
      :locale="$i18n.locale"
      placeholder=""
      reset-button
      close-button
      v-bind="{ ...$props, ...$attrs }"
      @keyup.enter.native="$emit('enter')"
      @input="handleInput"
    />
    <label
      :for="id"
      class="input__label label--indented"
      :style="[isBirthDate ? { position: 'absolute', top: '-1.4rem', left: '0' } : '']"
      :class="{ input__label_focus: showLabel || !!currentValue }"
    >
      {{ $attrs.placeholder || $t($case.title(field.label)) }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      default: null,
    },

    appendToBody: {
      type: Boolean,
      default: true,
    },

    hint: {
      type: String,
      default: null,
    },

    error: {
      type: String,
      default: null,
    },

    isBirthDate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentValue: null,
      showLabel: false,
    }
  },
  computed: {
    id() {
      return this.$attrs.id || this.$case.snake(`${this.$attrs.placeholder} ${this.$randStr(4)}`)
    },
  },
  watch: {
    value: {
      handler() {
        this.currentValue = this.value
      },
      immediate: true,
    },
  },
  methods: {
    handleInput() {
      this.$emit('input', this.currentValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.prozess-input {
  .input__label {
    position: absolute;
    top: 0.72rem;
    left: 1rem;
    font-size: 11px;
    color: var(--colour--input-placeholder);
    transition: all 0.15s ease-in-out;

    &.label--indented {
      left: 3rem;
    }
  }

  input:focus,
  input:not(:placeholder-shown) {
    .input__label {
      top: -1.45rem;
      left: 0;
      font-size: 10px;
      color: var(--colour--text-primary);
    }
  }
}

.input__label_focus {
  top: -1.45rem !important;
  left: 0 !important;
  font-size: 10px;
  color: var(--colour--text-primary) !important;
}

.input__date {
  width: 100%;
  background: var(--colour--input);
  border-radius: 0.357rem !important;
}
</style>

<style lang="scss">
.input__date .mx-input-wrapper .mx-input {
  font-size: 1rem !important;
  padding-left: 20px;
}
</style>
