<template>
  <div>
    <router-link
      v-if="hasPermission && !isDirectDownload"
      :to="to"
      @click.native="onClick"
    >
      <slot
        v-if="!!$slots['custom-title']"
        name="custom-title"
      />
      <span v-else>{{ title }}</span>
    </router-link>
    <template v-else>
      <slot
        v-if="!!$slots['custom-title']"
        name="custom-title"
      />
      <span v-else>
        <a
          v-if="isDirectDownload"
          href=""
        >
          <span
            :class="[!isDirectDownload ? 'tw-text-text-secondary' : '']"
            style="cursor: pointer"
            @click.prevent="downloadReport"
          >
            {{ title }}</span>
        </a>
        <span
          v-else
          class="tw-text-text-secondary"
        >{{ title }}</span>
      </span>
    </template>
  </div>
</template>

<script>
import imexService from '@/services/imex'

export default {
  props: {
    hasPermission: {
      type: Boolean,
      default: true,
    },
    to: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    isDirectDownload: {
      type: Boolean,
      required: false,
      default: false,
    },
    onClick: {
      type: Function,
      default: null,
    },
  },
  methods: {
    downloadReport() {
      if (this.isDirectDownload) {
        this.showNotice('export')
        imexService.downloadReport(this.to.params.id)
      }
    },
    showNotice(io) {
      this.$swal({
        title: this.$t(`imex-action-${io}-notice`),
        icon: 'info',
        confirmButtonText: this.$t('Close'),
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
      })
    },
  },
}
</script>
