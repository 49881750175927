import API from '@axios'
import { apiHelper } from '../helpers/apiHelper'
import { stringify as toQuery } from 'query-string'

const API_URL = '/api/notifications'

export default {
  ...apiHelper(API_URL),
  getAllNotificationSettings() {
    return API.get(`${API_URL}/settings`)
  },

  updateNotificationSettings(payload) {
    return API.patch(`${API_URL}/settings`, payload)
  },

  markAllAsRead() {
    return API.put(`${API_URL}/notification/read_all`)
  },

  toggleNotificationStatus(payload) {
    return API.put(`${API_URL}/settings/${payload}`)
  },

  getUnreadNotificationsCount() {
    return API.get(`${API_URL}/notification/unread`)
  },

  markAsRead(id) {
    return API.put(`${API_URL}/notification/${id}/read`)
  },

  fetchNotificationLists(query) {
    return API.get(`${API_URL}/notification/recent?${toQuery(query)}`)
  },
}
