<template>
  <div
    class="prozess-input tw-pt-3"
    :class="{
      'prozess-input__disabled': this.$attrs.disabled === '',
    }"
  >
    <b-input-group
      class="input-group-merge"
      :class="{
        'mb-2': !hint,
      }"
    >
      <b-input-group-prepend
        v-if="icon"
        is-text
      >
        <feather-icon :icon="icon" />
      </b-input-group-prepend>

      <b-input
        :id="id"
        v-model="currentValue"
        :class="{ 'form-control-with-append-icon': !!appendIcon }"
        v-bind="{ ...$props, ...$attrs }"
        v-on="$listeners"
        @input="handleInput"
        @blur="handleBlur"
        @keyup.enter="
          handleBlur()
          $emit('enter')
        "
      />

      <label
        :for="id"
        class="input__label"
        :class="{ 'label--indented': !!icon }"
      >
        {{ $attrs.placeholder }}
      </label>
      <!-- START: Append Icon -->
      <b-input-group-append
        v-if="appendIcon"
        :class="{ 'cursor-pointer': appendIconClickable }"
        is-text
        @click="handleAppendIconClick"
      >
        <feather-icon :icon="appendIcon" />
      </b-input-group-append>

      <!-- END: Append Icon -->
      <span
        v-if="error && !hint"
        class="invalid-feedback"
      >
        {{ $t(error) }}
      </span>
    </b-input-group>
    <div
      v-if="hint"
      class="d-flex justify-content-between mb-2"
      :class="{
        'flex-column-reverse': hintPosition === 'left',
      }"
    >
      <span
        v-show="error"
        class="invalid-feedback"
        style="flex: 1"
      >
        {{ $t(error) }}
      </span>
      <span
        style="flex: 2"
        class="field-hint"
        :class="{
          'text-left': hintPosition === 'left',
        }"
      >
        {{ $t(hint) }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Array],
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    hintPosition: {
      type: String,
      default: 'right',
    },
    error: {
      type: String,
      default: null,
    },

    appendIcon: {
      type: String,
      default: null,
    },

    // Start: for clickable append icon identifier

    appendIconClickable: {
      type: Boolean,
      default: false,
    },

    // End: for clickable append icon identifier
  },
  data() {
    return {
      currentValue: null,
    }
  },

  computed: {
    id() {
      return this.$attrs.id || this.$case.snake(`${this.$attrs.placeholder} ${this.$randStr(4)}`)
    },
  },

  watch: {
    value: {
      handler() {
        this.$nextTick(() => {
          this.currentValue = this.value
        })
      },
      immediate: true,
    },
  },
  methods: {
    handleBlur() {
      if (this.currentValue) {
        if (typeof this.currentValue === 'string') {
          this.currentValue = this.currentValue.trim()
        }

        this.nullifyIfNumber()

        this.$emit('input', this.currentValue)
      }
    },

    handleInput() {
      this.nullifyIfNumber()

      this.$emit('input', this.currentValue)
    },

    handleAppendIconClick() {
      this.$emit('appendIconClick', this.$attrs)
    },

    /**
     * This will change the `currentValue`'s value to `null` if
     * the field's type is `number` and
     * the `currentValue`'s value is an empty string ('')
     */
    nullifyIfNumber() {
      if (this.$attrs.type === 'number' && this.currentValue === '') {
        this.currentValue = null
      }
    },
  },
}
</script>

<style lang="scss">
.prozess-input {
  &__disabled,
  &[disabled='disabled'] {
    div.input-group > div.input-group-prepend {
      opacity: 0.5;
      div.input-group-text {
        margin-right: 1px;
      }
    }
  }

  input.form-control::placeholder {
    color: transparent !important;
  }

  .input__label {
    position: absolute;
    top: 0.72rem;
    left: 1rem;
    font-size: 11px;
    color: var(--colour--input-placeholder);
    transition: all 0.15s ease-in-out;

    &.label--indented {
      left: 3rem;
    }
  }
}

input.form-control:focus,
input.form-control:not(:placeholder-shown) {
  + .input__label {
    top: -1.45rem;
    left: 0;
    font-size: 10px;
    color: var(--colour--text-primary);
  }
}

::-webkit-calendar-picker-indicator {
  filter: invert(100%);
  width: 20px;
  height: 15px;
}
</style>
