import { AbilityBuilder, Ability } from '@casl/ability'
import authUserService from '../services/authUser'
import { dashboardService } from '@/services/dashboard'
import shortcuts from '@/services/shortcuts'

export default {
  install(Vue) {
    Vue.prototype.$getUserAndPermissions = async function () {
      const { can, rules } = new AbilityBuilder(Ability)

      const { data } = await authUserService.getUserInfo()
      const shortcutResponse = await shortcuts('shortcut').getAllByUser()
      const categorizedDashboardResponse = await dashboardService().getMyCategorizedDashboard()
      const { permissions } = data

      this.$store.dispatch('user/setUserInfo', { data, shortcutResponse, categorizedDashboardResponse })
      can('Read', 'AppUserGuide') // Gives all users the ability to Read the User Guide

      this.$i18n.locale = data.language || 'en'

      if (Array.isArray(permissions.permissions) && permissions.permissions.length) {
        permissions.permissions.forEach(({ name, operation }) => {
          can(operation, name)
        })
      }

      if (permissions.companyAdmin === true) {
        can('PerformAs', 'CompanyAdmin')
      }

      this.$ability.update(rules)
    }
  },
}
