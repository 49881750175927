import API from '@axios'
import { stringify as toQuery } from 'query-string'
import { apiHelper } from '../helpers/apiHelper'

const API_URL = '/api/data_feed'

export default {

  getOne(schema, id) {
    return API.get(`${API_URL}/${schema.schemaKey}/${schema.tableKey}?id==${id}`)
  },

}
