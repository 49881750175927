import { Entity, Schema } from '@/constants/app'

export default {
  noInquiryContact(state) {
    const isInquirySelected = state.fieldOptions.some(({ entityId }) => entityId === `${Schema.CORE}.${Entity.INQUIRY}`)
    const isContactSelected = state.fieldOptions.some(({ entityId }) => entityId === `${Schema.CRM}.${Entity.CONTACT}`)

    return isInquirySelected && !isContactSelected
  },
  noTodoContact(state) {
    const isTodoSelected = state.fieldOptions.some(({ entityId }) => entityId === `${Schema.CORE}.${Entity.TODO}`)
    const isContactSelected = state.fieldOptions.some(({ entityId }) => entityId === `${Schema.CRM}.${Entity.CONTACT}`)

    return isTodoSelected && !isContactSelected
  },

  /**
   * This is to check if "Company - Contact Relationship" is selected
   * when both Company and Contact Entities are selected.
   */
  noCompanyContactRelationship(state) {
    const companyEntityId = `${Schema.CRM}.${Entity.COMPANY}`
    const contactEntityId = `${Schema.CRM}.${Entity.CONTACT}`

    // This is to ensure that this check will only run if the first selected entity is either Company or Contact
    if (![companyEntityId, contactEntityId].includes(state.fieldOptions[0]?.entityId)) {
      return false
    }

    const isCompanySelected = state.fieldOptions.some(({ entityId }) => entityId === companyEntityId)
    const isContactSelected = state.fieldOptions.some(({ entityId }) => entityId === contactEntityId)
    const isContactRelationshipSelected = state.fieldOptions.some(({ entityId }) => entityId === `${Schema.CRM}.${Entity.COMPANY_HAS_CONTACT}`)

    return (isCompanySelected && isContactSelected) && !isContactRelationshipSelected
  },
}
