import API from '@axios'
import { stringify as toQuery } from 'query-string'
import { apiHelper } from '@/helpers/apiHelper'

const API_URL = '/api/metastatus'

export const metastatusService = type => ({
  ...apiHelper(`${API_URL}/${type}`),

  getOneResource(id, query) {
    return API.get(`${API_URL}/${type}/${id}/get?&${toQuery(query)}`)
  },

  async getDefaults(tableName) {
    return API.get(`${API_URL}/default/${type}/${tableName}`)
  },

})

export default {
  getAllByEntity({ schema, table }) {
    return API.get(`${API_URL}/${schema}/${table}`)
  },
  getCategorybyId(schema, table) {
    return API.get(`${API_URL}/category/${schema}/${table}`)
  },
  autocomplete: (schemaName, tableName, search) => API.get(`${API_URL}/${schemaName}/${tableName}/autocomplete?query=${search}`),
}
