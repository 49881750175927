import {
  round10,
  floor10,
  ceil10,
} from '@/helpers/round'

export default {
  install(Vue) {
    // Decimal round
    Vue.prototype.$round10 = round10
    // Decimal floor
    Vue.prototype.$floor10 = floor10
    // Decimal ceil
    Vue.prototype.$ceil10 = ceil10
  },
}
