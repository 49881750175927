import { stringify } from 'query-string'
import { apiHelper } from '@/helpers/apiHelper'
import API from '@axios'

const API_URL = '/api/advance_field'
const ORDER_ENDPOINT = '/api/entity_order'

export default {
  ...apiHelper(API_URL),

  getOne: ({ schema, table, key }) => API.get(`${API_URL}/${schema}/${table}/${key}`),

  getAllEntities: () => API.get(`${API_URL}/entities`),

  getDataFeed: (query = { page: 0 }, schema, table) => API.get(`/api/data_feed/${schema}/${table}?${stringify(query)}`),
  getFieldsByEntity: ({
    schema, schemaKey, table, tableKey,
  }) => API.get(`${API_URL}/${schema || schemaKey}/${table || tableKey}`),

  getStandardFields: ({ schema, table }) => API.get(`${API_URL}/${schema}/${table}/standard_fields`),

  store: ({ schema, table }, payload) => API.post(`${API_URL}/${schema}/${table}`, payload),

  update: ({ schema, table, key }, payload) => API.put(`${API_URL}/${schema}/${table}/${key}`, payload),

  reorder: ({ schema, table }, payload) => API.post(`${ORDER_ENDPOINT}/${schema}/${table}`, payload),

  getorder: ({ schema, table }) => API.get(`${ORDER_ENDPOINT}/${schema}/${table}`),

  delete: ({ schema, table, id }) => API.delete(`${API_URL}/${schema}/${table}/${id}`),

  executeQuery: ({ schema, table, fieldKey }, query) => API.get(`${API_URL}/${schema}/${table}/${fieldKey}/execute?${stringify(query)}`),

  executeQueries: ({ schema, table, fieldKeys = [] }, query) => API.post(`${API_URL}/${schema}/${table}/execute/queries`, fieldKeys, { params: { ...query } }),
}
