<template>
  <div class="prozess-search-input d-flex">
    <b-input-group class="input-group-merge table-search">
      <b-input-group-prepend is-text>
        <feather-icon icon="SearchIcon" />
      </b-input-group-prepend>
      <b-form-input
        v-model="currentValue"
        class="pr-4"
        v-bind="{ ...$props, ...$attrs }"
        v-on="$listeners"
        @input="handleInput"
      />
    </b-input-group>
    <div
      v-if="currentValue !== ''"
      class="prozess-search-input__clear"
    >
      <div class="prozess-search-input__clear-inner d-flex align-items-center">
        <feather-icon
          icon="XCircleIcon"
          size="16"
          class="mr-1 cursor-pointer"
          @click="clear"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Array],
      default: null,
    },
  },
  data() {
    return {
      currentValue: '',
    }
  },
  watch: {
    value: {
      handler() {
        this.currentValue = this.value
      },
      immediate: true,
    },
  },
  methods: {
    handleInput() {
      this.$emit('input', this.currentValue)
    },
    clear() {
      this.$emit('input', '')
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/scss/prozess-search-input.scss';
</style>
