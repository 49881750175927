import API from '@axios'
import { stringify as toQuery } from 'query-string'
import { apiHelper } from '../helpers/apiHelper'

const API_URL = '/api/iam/users/employees'

export default {
  ...apiHelper(API_URL),
  getHistory(id, payload = {
    size: 25,
  }) {
    return API.get(`${API_URL}/${id}/history?${toQuery(payload)}`)
  },
  searchEmployee(search) {
    return API.get(`${API_URL}/autocomplete?${toQuery({
      query: search,
    })}`)
  },
  resendInvitation(uuid) {
    return API.put(`${API_URL}/${uuid}/resend-invitation`, {})
  },

  assignedToEntities(userId, payload) {
    return API.post(`/api/iam/users/${userId}/assign_to_entities`, payload)
  },

  getAssignedGroups(schemaName, tableName, id) {
    return API.get(`/api/iam/users/${schemaName}/${tableName}/${id}`)
  },
  deleteAssignedGroup(id, payload) {
    return API.delete(`/api/iam/users/${id}/remove_from_entities`, { data: payload })
  },

}
