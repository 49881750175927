import authUserService from '@/services/authUser'
import { dashboardService } from '@/services/dashboard'
import notificationProfile from '@/services/notificationProfile'

export default {
  namespaced: true,
  state: {
    user: null,
    fields: [],
    categorizedDashboardLists: [],
    shortcuts: [],
    notifications: [],
    unreadNotificationCount: null,
  },
  getters: {
    fullName(state) {
      const { user } = state
      return `${user.firstName} ${user.lastName}`
    },
    customEntities(state) {
      return state.user ? state.user.customEntities : []
    },

    unreadNotificationCountDisplay(state) {
      return state.unreadNotificationCount > 99 ? '99+' : state.unreadNotificationCount
    },

    visibleShortcuts(state) {
      return state.shortcuts.filter(shortcut => shortcut.visible)
    },
  },
  mutations: {
    REMOVE_CUSTOM_ENTITY(state, key) {
      state.user.customEntities = state.user.customEntities.filter(item => item.key !== key)
    },

    SET_USER_INFO(state, payload) {
      state.user = payload
    },

    SET_USER_FIELDS(state, fields) {
      state.fields = fields
    },

    SET_UNREAD_NOTIFICATION_COUNT(state, count) {
      state.unreadNotificationCount = count
    },

    SET_CATEGORIZEDDASHBOARD_LISTS(state, payload = []) {
      if (Array.isArray(payload) && payload.length) {
        const dashboards = payload.map(dashboard => ({
          ...dashboard,
          title: dashboard.name,
          href: `/dashboard/${dashboard.uuidKey}`,
          parent: 'dashboard',
        }))
        state.categorizedDashboardLists = dashboards || []
      } else {
        state.categorizedDashboardLists = []
      }
    },

    SET_SHORTCUT_LISTS(state, payload = []) {
      if (Array.isArray(payload) && payload.length) {
        state.shortcuts = payload.map(item => ({
          ...item,
          externalLink: item.url,
          resource: 'System_Settings',
          action: 'Read',
        }))
      } else {
        state.shortcuts = []
      }
    },
  },
  actions: {
    removeCustomEntity({ commit }, key) {
      commit('REMOVE_CUSTOM_ENTITY', key)
    },
    async getUserInfo({ commit }) {
      const { data } = await authUserService.getUserInfo()
      const { dashboardData = data } = await dashboardService('dashboard').getAll()
      commit('SET_USER_INFO', data)
    },

    async getUnreadNotificationsCount({ commit }) {
      const { data } = await notificationProfile.getUnreadNotificationsCount()
      commit('SET_UNREAD_NOTIFICATION_COUNT', data)
    },
    setUserInfo({ commit }, payload) {
      commit('SET_USER_INFO', payload.data)
      commit('SET_SHORTCUT_LISTS', payload.shortcutResponse?.data ?? [])
      commit('SET_CATEGORIZEDDASHBOARD_LISTS', payload.categorizedDashboardResponse?.data ?? [])
    },

    updateDashboardList({ commit }, payload) {
      commit('SET_CATEGORIZEDDASHBOARD_LISTS', payload.categorizedDashboardResponse?.data ?? [])
    },
  },
}
