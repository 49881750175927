<template>
  <span
    :id="`${id}__container`"
    ref="container"
    class="copy-to-clipboard"
    :class="{ 'tw-hidden': hidden }"
  >
    <feather-icon
      :id="id"
      icon="ClipboardIcon"
      class="tw-cursor-pointer tw-top-0 tw-right-0 tw-rounded"
      :class="`tw-${clipboardPosition}`"
      style="background-color: var(--colour--card-bg)"
      :color="copied ? 'var(--success)' : 'var(--colour--secondary)'"
      @click="copyToClipboard"
    />
    <b-tooltip
      no-fade
      :container="`${id}__container`"
      :delay="0"
      :target="id"
      :title="tooltipTitle"
      placement="left"
    />
  </span>
</template>

<script>
import { markupToText } from '@/helpers/app'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },

    hidden: {
      type: Boolean,
      default: true,
    },

    text: {
      type: [String, Boolean, Number],
      required: true,
    },

    clipboardPosition: {
      type: String,
      default: 'absolute',
    },
  },

  data() {
    return {
      copied: false,
    }
  },

  computed: {
    tooltipTitle() {
      return this.copied ? `${this.$t('Copied')}!` : this.$t('Copy')
    },
  },

  methods: {
    async copyToClipboard() {
      await this.$copyText(markupToText(this.text?.toString() || ''), this.$refs.container)

      this.copied = true
      this.$tick(() => {
        this.copied = false
      }, 1000)
    },
  },
}
</script>
