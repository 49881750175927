var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"prozess-input tw-pt-3",class:{
    'prozess-input__disabled': this.$attrs.disabled === '',
  }},[_c('b-input-group',{staticClass:"input-group-merge",class:{
      'mb-2': !_vm.hint,
    }},[(_vm.icon)?_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":_vm.icon}})],1):_vm._e(),_c('b-input',_vm._g(_vm._b({class:{ 'form-control-with-append-icon': !!_vm.appendIcon },attrs:{"id":_vm.id},on:{"input":_vm.handleInput,"blur":_vm.handleBlur,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.handleBlur()
        _vm.$emit('enter')}},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},'b-input',{ ..._vm.$props, ..._vm.$attrs },false),_vm.$listeners)),_c('label',{staticClass:"input__label",class:{ 'label--indented': !!_vm.icon },attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.$attrs.placeholder)+" ")]),(_vm.appendIcon)?_c('b-input-group-append',{class:{ 'cursor-pointer': _vm.appendIconClickable },attrs:{"is-text":""},on:{"click":_vm.handleAppendIconClick}},[_c('feather-icon',{attrs:{"icon":_vm.appendIcon}})],1):_vm._e(),(_vm.error && !_vm.hint)?_c('span',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t(_vm.error))+" ")]):_vm._e()],1),(_vm.hint)?_c('div',{staticClass:"d-flex justify-content-between mb-2",class:{
      'flex-column-reverse': _vm.hintPosition === 'left',
    }},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticClass:"invalid-feedback",staticStyle:{"flex":"1"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.error))+" ")]),_c('span',{staticClass:"field-hint",class:{
        'text-left': _vm.hintPosition === 'left',
      },staticStyle:{"flex":"2"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.hint))+" ")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }