var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"prozess-search-dropdown"},[_c('div',{staticStyle:{"flex":"1","display":"flex","position":"relative","height":"100%"}},[_c('b-input-group',{staticClass:"input-group-merge",class:{
        'prozess-search-dropdown__disabled': _vm.data !== null || _vm.disabled,
      }},[(_vm.data !== null)?[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":_vm.icon}})],1),_c('input',{staticClass:"form-control",attrs:{"disabled":true},domProps:{"value":_vm.displayData}})]:[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":_vm.icon}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchValue),expression:"searchValue"}],staticClass:"form-control w-full",attrs:{"placeholder":_vm.placeholder,"type":"text","disabled":_vm.disabled},domProps:{"value":(_vm.searchValue)},on:{"focus":_vm.handleFocus,"keyup":_vm.handleKeyup,"blur":_vm.handleBlur,"keydown":_vm.handleKeydown,"input":function($event){if($event.target.composing)return;_vm.searchValue=$event.target.value}}})]],2),_c('div',{staticClass:"prozess-search-dropdown__new-label"},[(_vm.data && _vm.data.isNew && !_vm.loading)?_c('div',{staticClass:"prozess-search-dropdown__new-label-text d-flex align-items-center"},[_c('div',[_vm._v(_vm._s(_vm.$t(_vm.newLabel)))])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"dropdown-search-loader"},[_c('b-spinner')],1),((_vm.data !== null || _vm.searchValue) && !_vm.loading && _vm.disabled === false)?_c('div',{staticClass:"clear-button tw-text-muted",on:{"click":_vm.clear}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1):_vm._e(),(_vm.initialOptions.length)?_c('div',{staticClass:"dropdown-icon",class:{
          'dropdown-icon--active': _vm.isFocused,
        }},[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon"}})],1):_vm._e()])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showOptions),expression:"showOptions"}],ref:"options",staticClass:"options"},[_c('ul',{staticClass:"searched-items"},[(_vm.canHandleNewItem && _vm.currentlyTyping === false && _vm.searchValue !== '')?_c('li',{class:{ selected: _vm.selectedItem === null },on:{"mouseover":_vm.handleMouseOver,"mouseout":_vm.handleMouseOut,"click":_vm.handleNewItem}},[_vm._v(" "+_vm._s(_vm.newItemMessage || _vm.$t('ADD_NEW_ITEM'))+" ")]):_vm._e(),_vm._l((_vm.computedOptions),function(option,index){return _c('li',{key:index,staticClass:"d-flex flex-column",class:{
          selected: _vm.selectedItem === index + 1,
        },attrs:{"data-id":index + 1},on:{"mouseover":_vm.handleMouseOver,"mouseout":_vm.handleMouseOut,"click":function($event){return _vm.handleOptionClick(option)}}},[_c('span',[_vm._v(_vm._s(option.label))]),_vm._l((_vm.processAdditionalLabel(option)),function(_label){return _c('span',{key:_label,staticStyle:{"font-size":"0.8rem"}},[_vm._v(" "+_vm._s(_label)+" ")])})],2)}),(_vm.canHandleNewItem === false && _vm.currentlyTyping === false && _vm.options.length === 0)?_c('p',{staticClass:"no-item-found"},[_vm._v(" "+_vm._s(_vm.noItemFoundMessage || _vm.$t('No item found'))+" ")]):_vm._e()],2)]),(_vm.hint || _vm.error)?_c('div',{staticClass:"d-flex justify-content-between mb-2"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticClass:"invalid-feedback",staticStyle:{"flex":"1"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.error))+" ")]),_c('span',{staticClass:"field-hint",staticStyle:{"flex":"2"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.hint))+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }