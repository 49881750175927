import API from '@axios'
// import { stringify as toQuery } from 'query-string'
// import { apiHelper } from '../helpers/apiHelper'

const API_URL = '/api/data/from'

export const standardAndCustomEntityRelationshipService = (schema, tableName, id) => ({
  getEntitiesUnderStandardEntity() {
    return API.get(`${API_URL}/${schema}/${tableName}/${id}/all_related_custom_entities`)
  },
  getMetadata(mainSchema, mainTable) {
    return API.get(`${API_URL}/${schema}/${tableName}/of/${mainSchema}/${mainTable}`)
  },
  getCrmInfo() {
    return API.get(`/api/crm/${tableName}/info`)
  },
})
