import DOMPurify from 'dompurify'

export default {
  install(Vue) {
    Vue.prototype.$displayTransaction = function ({ interpretation: i }) {
      let content = ''
      if (!i) {
        content = this.$t('Transaction occured.')
      }
      let name = this.$t('An entity outside the system')
      if (i.externalModification === false) {
        name = `${i.firstName} ${i.lastName}`
      }
      content = `${name} ${i.text.substring(0, 500)}`

      content = content.split('\\n').join('<br>')

      return DOMPurify.sanitize(content, { ALLOWED_TAGS: ['br'] })
    }
  },
}
