export const AdvancedSearchCondition = {
  EQUAL: '=',
  LT: '<',
  GT: '>',
  GTE: '>=',
  LTE: '<=',
  NOT_EQUAL: '<>',
  ILIKE: 'ILIKE',
  IS_NULL: 'IS NULL',
  IS_NOT_NULL: 'IS NOT NULL',
  IS_TRUE: 'IS TRUE',
  IS_NOT_TRUE: 'IS NOT TRUE',
}

export const Join = {
  LEFT_OUTER: 'LEFT OUTER JOIN',
  RIGHT_OUTER: 'RIGHT OUTER JOIN',
  INNER: 'INNER JOIN',
}

export const nullableConditions = [
  AdvancedSearchCondition.IS_TRUE,
  AdvancedSearchCondition.IS_NOT_TRUE,
  AdvancedSearchCondition.IS_NULL,
  AdvancedSearchCondition.IS_NOT_NULL,
]

export const QueryType = {
  GLOBAL: 'global',
  PERSONAL: 'personal',
}

export const FormAction = {
  CREATE: 'create',
  EDIT: 'edit',
  VIEW: 'view',
  RUN: 'run',
}
