export default {
  formModel(state) {
    const baseData = state.baseData
    const chartConfig = state.chartConfig
    const customizableFilterFields = state.columns.map(column => column.fieldInfo)

    const filters = state.columns.filter(column => column.selectedFilterData.operationValue).map(filtered => {
      const selectedFilterData = filtered.selectedFilterData
      return {
        paramValue: selectedFilterData.paramValue,
        isParamUserField: selectedFilterData.isParamUserField,
        operation: selectedFilterData.operationValue,
        field: filtered.fieldInfo,
        isParamUserAccessible: selectedFilterData.userAccessible,

      }
    })
    const widgetConfiguration = {
      type: state.selectedWidget,
      datasourceUuidKey: baseData?.dataSource?.uuidKey,
      customizableFilterFields,
      filters,
    }

    const groupBy = state.columns.filter(col => col.isGroupBy).map(_col => ({ field: _col.fieldInfo }))

    // START: Inside widgetConfiguration

    if (state.selectedWidget === 'KPI') {
      widgetConfiguration.singleNumberConfiguration = {
        aggregationFunction: state.widgetValue.type?.selectedAggregates?.key,
        field: state.widgetValue.type?.fieldInfo,
      }
    }
    if (state.selectedWidget === 'LINE' || state.selectedWidget === 'BAR' || state.selectedWidget === 'PIE' || state.selectedWidget === 'TREE') {
      const countConfigurations = chartConfig.values.map(value => {
        const countField = value.value.fieldInfo
        return {
          countField,
          description: value.name,
          countAggregationFunction: value.value.selectedAggregates?.key,
        }
      })
      widgetConfiguration.countLabelConfiguration = {
        countConfigurations,
        description: chartConfig?.name,
        labelField: chartConfig?.field?.fieldInfo,

      }
    }

    if (state.selectedWidget === 'TABLE') {
      widgetConfiguration.tableSorting = [
        {
          description: state.widgetValue.description,
          field: customizableFilterFields[0],
        },
      ]
    }

    // END: Inside widgetConfiguration
    return {
      name: baseData?.name,
      description: baseData?.description,
      widgetConfiguration,
      groupBy,
    }
  },

  formValid(state) {
    if (!state.selectedWidget) return false
    if (state.selectedWidget === 'KPI') {
      return !!state.widgetValue?.type?.key ?? false
      // return Object.values(state.widgetValue.type).every(val => !val)
    }

    if (state.selectedWidget === 'LINE' || state.selectedWidget === 'BAR' || state.selectedWidget === 'PIE' || state.selectedWidget === 'TREE') {
      const config = state.chartConfig
      const isFieldValid = !!config.name && typeof config.field === 'object'
      const isValuesValid = config?.values?.every(value => typeof value.value === 'object' && !!value.name)

      return isValuesValid && isFieldValid
    }

    return true
  },

  columnsWithFilters(state) {
    return state.columns.filter(column => column.selectedFilterData.operationValue)
  },

  columnsWithAggregation(state) {
    return state.columns.filter(col => col.selectedAggregates)
  },

  getWidgetConfiguration(state) {
    return state.widgetConfiguration
  },
}
