import { stringify as toQuery } from 'query-string'
import API from '@axios'
import { Schema } from '@/constants/app'

export const toURLSearchParams = data =>
  Object.keys(data).reduce((acc, key) => {
    acc.append(key, data[key])
    return acc
  }, new URLSearchParams())

export const apiHelper = apiUrl => ({
  getAll: (query = { page: 0 }) => API.get(`${apiUrl}?${toQuery(query)}`),
  getOne: (id, query) => API.get(`${apiUrl}/${id}?&${toQuery(query)}`),
  store: payload => API.post(`${apiUrl}`, payload),
  update: (id, payload) => API.put(`${apiUrl}/${id}`, payload),
  patch: (id, payload) => API.patch(`${apiUrl}/${id}`, payload),
  destroy: (id, query) => API.delete(`${apiUrl}/${id}?${toQuery(query)}`),
})

export function getSchemaName(key) {
  switch (key) {
    case 'note':
      return Schema.CORE

    case 'company':
    case 'contact':
      return Schema.CRM

    default:
      return Schema.CUSTOM
  }
}

export default apiHelper
