<template>
  <div class="resource-view">
    <div
      class="resource-view__tabs"
      :class="{ 'tw-max-w-full': expanded }"
    >
      <div
        v-if="expanded"
        class="d-flex justify-content-end"
        style="height: 0px;"
      >
        <button
          id="edit-button"
          class="btn btn-primary d-flex justify-content-around align-items-center"
          :class="{
            'icon-disabled': canUpdate === false,
          }"
          :style="buttonTopAdjust ? 'position:relative; top: -44px; height: 35px' : 'height: 35px' "
          @click="$emit('edit')"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
          />
          <span>{{ $t('Edit') }}</span>
        </button>
        <b-tooltip
          v-if="!canUpdate"
          target="edit-button"
        >
          {{ $t('You dont have permission to edit') }}
        </b-tooltip>
      </div>
      <slot name="body" />
    </div>
    <div
      v-show="!expanded"
      class="resource-view__details"
    >
      <div class="d-flex justify-content-end">
        <button
          id="edit-button"
          class="btn btn-primary d-flex justify-content-around align-items-center"
          :class="{
            'icon-disabled': canUpdate === false,
          }"
          @click="$emit('edit')"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
          />
          <span>{{ $t('Edit') }}</span>
        </button>
        <b-tooltip
          v-if="!canUpdate"
          target="edit-button"
        >
          {{ $t('You dont have permission to edit') }}
        </b-tooltip>
      </div>
      <div class="resource-view__details-card card mt-1 resource-view__scrollable">
        <div class="card-body px-3 py-4">
          <b-overlay
            :show="loading"
            spinner-variant="primary"
            rounded="sm"
            variant="transparent"
            style="height: 100%"
            class="d-flex flex-column"
          >
            <slot name="topDetails" />

            <!-- START: STATUS -->
            <div class="d-flex mb-3 flex-column">
              <div class="resource-view__field-labels tw-mb-1">
                <span class="resource-view__label">{{ $t($case.title(status.label)) }}</span>
              </div>
              <div class="resource-view__field-labels d-flex flex-row">
                <feather-icon
                  icon="ActivityIcon"
                  class="mr-2 tw-text-primary"
                />
                <div class="resource-view__field-value">
                  <span> {{ getStatus(resource.statusId) }}</span>
                </div>
              </div>
            </div>

            <!-- END: Status -->

            <div
              v-for="(field, i) of fieldsManagement"
              :key="i"
              class="d-flex mb-2 flex-column"
            >
              <div class="resource-view__field-labels tw-mb-1">
                <span class="resource-view__label">
                  {{ $t($case.title(field.label)) }}
                </span>
              </div>
              <div class="d-flex flex-row">
                <feather-icon
                  :icon="getSideBarIcons(field.customField, field.type, field.key)"
                  class="mr-2 tw-text-primary"
                />
                <div class="resource-view__field-value">
                  <span v-if="field.key === 'priorityName'">{{ `${resource[field.key]}-${resource.priorityRank}` }} </span>
                  <span v-else>
                    <feather-icon
                      v-if="field.type == 'BOOL'"
                      size="17"
                      :icon="booleanIcon[field.customField ? getCustomFieldText(field.key) : getInfoText(camelize(field.key))]"
                    />
                    <span
                      v-else
                      v-html="field.customField ? getCustomFieldText(field.key) : getInfoText(camelize(field.key))"
                    />
                  </span>
                  <prozess-copy-to-clipboard
                    :id="`copy-key-${i}`"
                    :text="copyClipboard(field.key)"
                  />
                </div>
              </div>
            </div>

            <div
              v-for="(item, i) in xTo1Relationships"
              :key="`rel-${i}`"
              class="d-flex mb-3"
            >
              <div class="resource-view__field-labels d-flex flex-column">
                <span class="resource-view__label">
                  {{ $t(item.label) }}
                </span>
              </div>
              <div class="d-flex flex-column justify-content-around">
                <span class="resource-view__field-value">
                  <router-link
                    v-if="checkRelationshipReadPermission(item.value)"
                    :to="formatRouterLink(item.value)"
                  >
                    {{ item.value.rowName }}
                  </router-link>
                  <template v-else>
                    {{ item.value.rowName }}
                  </template>
                  <prozess-copy-to-clipboard
                    :id="`copy-relationship-${i}`"
                    :text="item.value.rowName"
                  />
                </span>
              </div>
            </div>

            <form-notification
              :id="$route.params.id"
              ref="formNotification"
              class="tw-mb-4"
              :is-viewing="true"
            />
          </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormNotification from '@/components/Forms/FormNotification.vue'
import { customFieldTypeIcons } from '@/constants/customFieldTypes'
import { standardAndCustomEntityRelationshipService } from '@/services/relatedEntity'
import { formatRouterLink, toPerspective } from '@/helpers/customEntity'
import { RelationshipFilter } from '@/constants/relationshipTypes'
import { GenericTabs } from '@/constants/app'
import { getCustomFieldText, getInfoText } from '@/helpers/customFields'
import { getInfoIcons } from '@/helpers/inquiryIcons'
import ProzessCopyToClipboard from '@/@core/components/ProzessCopyToClipboard.vue'
import advancedFieldMgmt from '@/services/advancedFieldMgmt'
import fieldMixins from '@/mixins/fields'
import dataFeedService from '@/services/datafeed'
import FeatherIcon from './feather-icon/FeatherIcon.vue'

const { X_TO_ONE, X_TO_MANY } = RelationshipFilter

export default {
  name: 'ProzessResourceView',
  components: {
    ProzessCopyToClipboard,
    FormNotification,
    FeatherIcon,
  },

  mixins: [fieldMixins],

  props: {
    canUpdate: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Object,
      default: () => {},
    },
    customFields: {
      type: Object,
      default: null,
    },
    resource: {
      type: Object,
      default: () => {},
    },
    tableName: {
      type: String,
      default: null,
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      readMore: false,
      relationshipService: null,
      fieldsManagement: [],
      status: {
        label: 'Status',
        value: 'N/A',
      },
      entity: {
        columns: [],
        relationships: [],
        xTo1Relationships: [],
        xToNRelationships: [],
      },
      xTo1Relationships: [],
      connections: [],
      customFieldTypeIcons,
      booleanIcon: {
        true: 'CheckCircleIcon',
        false: 'XCircleIcon',
      },
    }
  },
  computed: {
    showReadMore() {
      // NOT USING ANYMORE
      return this.fieldsManagement.length >= 9 && !this.readMore
    },

    fieldsToShow() {
      // NOT USING ANYMORE
      return this.readMore ? this.fieldsManagement : this.fieldsManagement.slice(0, 8)
    },

    expanded() {
      // This is a sample on how to show a fixed sidebar for resource info
      // if (this.$route.name === 'company-view' && this.$route.params.tab === GenericTabs.COMPANIES) return false
      // if (this.$route.name === 'contact-view' && this.$route.params.tab === GenericTabs.CONTACTS) return false
      return true
    },
    buttonTopAdjust() {
      if (this.$route.name === 'company-view') return true
      if (this.$route.name === 'contact-view') return true
      return false
    },
  },

  watch: {
    statusOptions: {
      handler(value) {
        if (value) this.getStatusName()
      },
    },
    resource: {
      handler(value) {
        if (value.uuid && ['company', 'contact'].includes(this.tableName)) {
          this.$refs.formNotification.fetchAssignedEntities()
          this.relationshipService = standardAndCustomEntityRelationshipService('crm', this.tableName, value.uuid)
          this.getConnectedEntities()
        }

        this.fetchFieldManagementsData()
        // FETCHING of status since it has a seperate API
        if (this.$route.name.includes('company') || this.$route.name.includes('contact')) {
          this.fetchDataFeedData()
        }
      },
      immediate: true,
    },
  },

  methods: {
    async fetchDataFeedData() {
      let status = null
      const { response } = await this.$async(dataFeedService.getOne(this.getSchemaTableName, this.$route.params.idFeed))

      if (response) {
        status = response.data.pageItems[0]
      }
    },
    async fetchFieldManagementsData() {
      const { response } = await this.$async(advancedFieldMgmt.getFieldsByEntity(this.getSchemaTableName))
      const data = response.data
      if (this.$route.name.includes('inquir') || this.$route.name.includes('todo')) {
        data.push(
          { key: 'companyName', label: 'Company Name', sortable: true },
          { key: 'contact', label: 'Contact', sortable: true },
          { key: 'statusName', label: 'Status', sortable: true },
          { key: 'priorityName', label: 'Priority', sortable: true },
          { key: 'categoryName', label: 'Category', sortable: true },
          { key: 'sourceName', label: 'Source', sortable: true },
          { key: 'assignedUserName', label: 'Assigned To', sortable: true },
        )
      }
      this.fieldsManagement = response.data
    },
    async getConnectedEntities() {
      const [entitiesResponse, crmInfoResponse] = await Promise.all([
        this.relationshipService.getEntitiesUnderStandardEntity(),
        this.relationshipService.getCrmInfo(),
      ])

      this.entity = {
        ...this.entity,
        ...crmInfoResponse.data,
      }

      this.connections = entitiesResponse.data

      this.formatRelationshipsByPerspective()
      this.formatResourceRelationships()
    },
    formatRelationshipsByPerspective() {
      const { xTo1, xToN } = toPerspective(this.tableName, this.entity.relationships, [X_TO_ONE, X_TO_MANY])
      this.entity.xTo1Relationships = xTo1
      this.entity.xToNRelationships = xToN
      this.$emit('relationshipsLoaded', xToN)
    },
    formatResourceRelationships() {
      /**
       * Prepares `resource.xTo1Relationships` data.
       * Makes sure that "1to1" and "Nto1" Relationships are
       * the only ones that will be displayed on the Side Details Panel
       */
      this.xTo1Relationships = this.connections.reduce((relationships, connection) => {
        // Finds the `connection.tableKey`'s Relationship Details
        const relationship = this.entity.xTo1Relationships.find(rel => connection.tableKey === rel.key2)

        /**
         * Checks if the Relationship details is found
         * and if the Custom Entity has existing Connections/Relationships
         */
        if (relationship && connection.rows[0]) {
          relationships.push({
            key: relationship.key2,
            label: relationship.label2,
            schema: relationship.schema2,
            value: connection.rows[0],
            type: relationship.type,
          })
        }

        return relationships
      }, [])
    },
    checkRelationshipReadPermission({ schemaName, tableKey }) {
      if (schemaName === 'crm') {
        return this.$can('Read', 'CRM')
      }

      if (schemaName === 'core') {
        const entities = {
          note: 'Note',
          document: 'Document',
        }

        return this.$can('Read', entities[tableKey])
      }

      /**
       * Returns Read Permission for Custom Entities
       * e.g. $can('Read', 'sales')
       */
      return this.$can('Read', tableKey)
    },
    formatRouterLink,

    getInfoText(key) {
      const { value } = getInfoText(this.resource, this.fields, key, v => this.$d(v), this.$case)

      return value
    },

    copyClipboard(key) {
      if (key === 'priorityName') {
        return `${this.resource[key]}-${this.resource.priorityRank || ''}`
      }
      return this.resource[this.camelize(key)] || ''
    },

    getCustomFieldText(key) {
      const field = this.customFields[key]
      if (field?.fieldType === 'BOOL' && !this.resource.customFields[key]) {
        return 'false'
      }

      if (!field) {
        return null
      }

      return getCustomFieldText(
        this.resource.customFields,
        field.fieldType,
        key,
        v => this.$n(v),
        v => this.$d(v),
      )
    },

    getSideBarIcons(isCustomField, type, key) {
      if ((this.$route.name.includes('inquiry') || this.$route.name.includes('todo')) && !isCustomField) {
        return getInfoIcons(key)
      }

      return customFieldTypeIcons[type]
    },

    getStatusName() {
      if (!this.resource.statusId) return 'N/A'
      this.status.value = this.statusOptions.find(({ metaStatusId }) => metaStatusId === this.resource.statusId).metaStatusName
    },
    getStatus(id) {
      try {
        return this.statusOptions && this.statusOptions.find(({ metaStatusId }) => metaStatusId === id).metaStatusName
      } catch (error) {
        return 'N/A'
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/resource-view.scss';
</style>
