import Vue from 'vue'

export const register = () => {
  Vue.mixin({

    computed: {
      hasSystemSettingsActions() {
        return this.$can('Update', 'System_Settings')
      },
      hasSystemSettingsRead() {
        return this.$can('Read', 'System_Settings')
      },
    },
    methods: {
      camelizeGlobal(val) {
        return val.replace(/_./g, x => x[1].toUpperCase())
      },
      titleTextOf(val) {
        return val.replace(/_./g, x => ` ${x[1].toUpperCase()}`).replace(/^./, x => x.toUpperCase())
      },
      async yupValidate(schema, data) {
        const { error } = await this.$async(schema
          .validate(data, { abortEarly: false }))
        return error ? error.inner.map(e => ({
          field: e.path,
          defaultMessage: e.message,
        })) : []
      },

      showWarning(title) {
        this.$swal({
          title: this.$t(title),
          icon: 'warning',
          confirmButtonText: this.$t('Close'),
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
          allowOutsideClick: false,
        })
      },

      showSuccess(title) {
        this.$swal({
          title: this.$t(title),
          icon: 'success',
          confirmButtonText: this.$t('Close'),
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
          allowOutsideClick: false,
        })
      },

      registerBusEvent(eventName, cb = () => { }) {
        this.$root.$off(eventName)
        this.$root.$on(eventName, cb)
      },

      doDebounce(timeoutKey, callback = () => { }, timeout = 800) {
        clearTimeout(this[timeoutKey])
        this[timeoutKey] = this.$tick(callback, timeout)
      },
    },
  })
}

export default {
  register,
}
