import API from '@axios'
import { stringify as toQuery } from 'query-string'

const API_URL = '/api/documents/signature'

export default {
  isEnabled() {
    return API.get(`${API_URL}/enable`)
  },
  toggle(isEnabled) {
    const value = isEnabled ? 'enable' : 'disable'
    return API.put(`${API_URL}/${value}`)
  },
  getState() {
    return API.get(`${API_URL}/state?decrypt=true`)
  },
  saveConfigAndDefaults(payload) {
    return API.put(`${API_URL}/enable_with_config_and_defaults`, payload)
  },
  requestSignature(payload) {
    return API.post(`${API_URL}/digital_signature`, payload)
  },
  searchEmployee(search) {
    return API.get(`${API_URL}/autocomplete/user_employee?${toQuery({
      query: search,
    })}`)
  },
  getSignatureDefaults() {
    return API.get(`${API_URL}/defaults`)
  },
  getSignatureDefault(id) {
    return API.get(`${API_URL}/defaults/${id}`)
  },
  getSignatureRequests(id) {
    return API.get(`${API_URL}/document/${id}`)
  },
  deleteSignatureRequest(id) {
    return API.delete(`${API_URL}/digital_signature/${id}`)
  },
}
