import API from '@axios'
import { stringify as toQuery } from 'query-string'
import { apiHelper } from '../helpers/apiHelper'

const API_URL = '/api/shortcuts'

export default tableName => ({
  ...apiHelper(`${API_URL}/${tableName}`),
  toggleShortcutVisibility(id, value) {
    return API.patch(`${API_URL}/shortcut/${id}/visibility?${toQuery({ visible: value })}`)
  },
  shortcutChangeOrdering(payload) {
    return API.patch(`${API_URL}/shortcut/order`, payload)
  },

  getAllByUser() {
    return API.get(`${API_URL}/shortcut/user`)
  },
})
