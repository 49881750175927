import state from '@/store/widget/state'
import mutations from '@/store/widget/mutations'
import getters from '@/store/widget/getters'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
