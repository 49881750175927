<template>
  <div
    class="prozess-file-upload"
    @drop="handleDrop"
    @dragover="handleDragOver"
    @click="handleClick"
  >
    <p
      v-if="!currentValue"
      class="prozess-file-upload__drag-here"
    >
      {{ $t('Drag and drop file here') }}<br> {{ $t('or') }}
    </p>
    <p v-else>
      {{ fileName }}
    </p>
    <button
      class="btn btn-secondary"
      type="button"
    >
      {{ $t('Browse') }}
    </button>
    <input
      ref="fileInput"
      type="file"
      @change="handleInputChange"
    >
  </div>
</template>

<script>

export default {
  props: {
    allowedMimeTypes: {
      type: Array,
      default: () => [],
    },
    value: {
      type: File,
      default: null,
    },
    maxFileSize: { // In MB
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      currentValue: null,
    }
  },
  computed: {
    fileName() {
      return this.currentValue.name
    },
  },
  watch: {
    value: {
      handler(value) {
        this.currentValue = value
        if (this.$refs.fileInput) {
          this.$refs.fileInput.value = '' // clear value, solves bug when edit form re-opens
        }
      },
      immediate: true,
    },
  },
  methods: {
    set(file) {
      const validations = [
        {
          condition: this.maxFileSize && this.maxFileSize < file.size / 1024 / 1024,
          message: `${this.$t('File should not exceed')} ${this.maxFileSize}mb`,
        },
        {
          condition: this.allowedMimeTypes.length && !this.allowedMimeTypes.includes(file.type),
          message: this.$t('File type not supported'),
        },
      ]

      let containsError = false

      validations.forEach(item => {
        if (containsError) return

        if (item.condition) {
          containsError = true
          this.$refs.fileInput.value = ''

          this.currentValue = null
          return this.$swal({
            title: item.message,
            icon: 'warning',
            confirmButtonText: this.$t('Close'),
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
            allowOutsideClick: false,
          })
        }
      })

      if (containsError) return

      this.currentValue = file
      this.$emit('input', this.currentValue)
    },
    handleClick() {
      this.$refs.fileInput.click()
    },
    handleInputChange(e) {
      const [file] = e.target.files
      this.set(file)
    },
    handleDrop(e) {
      e.stopPropagation()
      e.preventDefault()
      const { files } = e.dataTransfer
      const [file] = files
      this.set(file)
    },
    handleDragOver(e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/master-variables.scss';

.prozess-file-upload {
  border: 1px dashed $colour--text-muted;
  border-radius: 8px;
  background: $color--dark-a;
  cursor: pointer;
  height: 221px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    background: $color--dark-n;
  }

  p {
    text-align: center;
  }

  &__drag-here {
    color: $colour--text-muted;
  }

  input[type=file] {
    display: none;
  }
}
</style>
