import CustomFieldTypes from '@/constants/customFieldTypes'
import dayjs from 'dayjs'

export const sortByFavorite = fields => fields.sort(
  (a, b) => b.favorite - a.favorite,
)

export const formatForResource = (metadata, fieldData) => ({
  ...metadata.reduce((acc, field) => {
    const result = fieldData.find(
      data => field.id === data.metadataId,
    )

    if (result) {
      acc[field.key] = result.value
    }

    return acc
  }, {}),
})

export function getInputType(item) {
  switch (item.type) {
    case CustomFieldTypes.Number:
    case CustomFieldTypes.Percent:
      return 'number'

    case CustomFieldTypes.Email:
      return 'email'

    default:
      return 'text'
  }
}

/**
 * This is to format an advanced field's value.
 *
 * If the data starts with "[" and ends with "]", that means that data is a Multi-Select value.
 *
 * If that's the case, this function will remove those brackets.
 * This will also convert the data into an array if `toArrayIfMulti` is set to `true`
 */
export function advancedFieldValue(value, config = { toArrayIfMulti: false }) {
  if (typeof value === 'string' && value[0] === '[' && value[value.length - 1] === ']') {
    value = value.substring(1, value.length - 1)

    if (config?.toArrayIfMulti) {
      return value.split(',').map(v => v.trim())
    }
  }

  return value
}

export function getCustomFieldText(customFields, fieldType, key, $n, $d) {
  let value = customFields[key]

  value = advancedFieldValue(value)

  if (typeof value === 'object') {
    return value?.map(item => item.name).join(',') ?? value
  }

  if (fieldType === CustomFieldTypes.Percent) {
    return `${$n(value * 100)}%` ?? value
  }

  if (fieldType === CustomFieldTypes.Number) {
    return $n(value) ?? value
  }

  if (fieldType === CustomFieldTypes.Date) {
    return dayjs(new Date(value)).format('MMMM DD, YYYY') ?? value
  }

  if (fieldType === CustomFieldTypes.DateTime) {
    return dayjs(new Date(value)).format('MMMM DD, YYYY hh:mm A') ?? value
  }

  return value ?? ''
}

export function getInfoText(resource, fields, fieldKey, $d, $case, customField = false) {
  let fieldType = CustomFieldTypes.Text
  let value = ''
  value = resource[fieldKey] ?? ''
  if (typeof resource[fieldKey] === 'object') {
    fieldType = CustomFieldTypes.Text
    value = resource[fieldKey] && Array.isArray(resource[fieldKey])
      ? resource[fieldKey].map(item => item.name).join(',')
      : ''
  }
  const dateTimeTest = new RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/)
  if (dateTimeTest.test(resource[fieldKey])) {
    fieldType = CustomFieldTypes.DateTime
    value = dayjs(new Date(resource[fieldKey])).format('MMMM DD, YYYY hh:mm A')
  } else if (fields[fieldKey]?.includes('Date')) {
    fieldType = CustomFieldTypes.Date
    value = resource[fieldKey] ? dayjs(new Date(resource[fieldKey])).format('MMMM DD, YYYY') : ''
  }

  return {
    value,
    fieldType,
    fieldKey,
    fieldLabel: $case.title(fieldKey),
    customField,
  }
}

export default {
  sortByFavorite,
  formatForResource,
}
