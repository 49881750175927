import { stringify } from 'query-string'
import { Entity, Schema } from '@/constants/app'
import { downloadBlob } from '@/helpers/app'
import API from '@axios'

const API_URL = '/api'

export default {
  downloadReport: reportId => {
    const url = `${API_URL}/report/initiate/${reportId}`
    // API.get(url, { responseType: 'blob' }).then(({ data }) => downloadBlob(data, 'report.xlsx'))
    return API.put(`${url}`)
  },
  downloadTemplate: ({ schemaName, entityType }) => {
    entityType = entityType === Entity.NOTE ? 'notes' : entityType

    const url =
      schemaName === Schema.CUSTOM ? `${API_URL}/${schemaName}/table/${entityType}/import_template` : `${API_URL}/${schemaName}/${entityType}/import_template`

    API.get(url, { responseType: 'blob' }).then(({ data }) => downloadBlob(data, `template-${entityType}.xlsx`))
  },

  initiateImport: ({ schemaName, entityType }, payload) => {
    entityType = entityType === Entity.NOTE ? 'notes' : entityType

    const url =
      schemaName === Schema.CUSTOM ? `${API_URL}/${schemaName}/table/${entityType}/initiate_import` : `${API_URL}/${schemaName}/${entityType}/initiate_import`

    return API.post(url, payload)
  },

  initiateExport: ({ schemaName, entityType }, query = {}) => {
    entityType = entityType === Entity.NOTE ? 'notes' : entityType

    const url =
      schemaName === Schema.CUSTOM ? `${API_URL}/${schemaName}/table/${entityType}/initiate_export` : `${API_URL}/${schemaName}/${entityType}/initiate_export`

    return API.put(`${url}?${stringify(query)}`)
  },

  getRecentImports: ({ schemaName, entityType }, query = {}) => {
    schemaName = entityType === Entity.NOTE ? 'notes' : schemaName

    query = {
      ...query,
      schemaAndTableName: `${schemaName}.${entityType}`,
    }

    return API.get(`${API_URL}/core/bulk_data/imports/recent?${stringify(query)}`)
  },

  getActiveImports: ({ schemaName, entityType, status }, query = {}) => {
    schemaName = entityType === Entity.NOTE ? 'notes' : schemaName

    query = {
      ...query,
      status,
      schemaAndTableName: `${schemaName}.${entityType}`,
    }

    return API.get(`${API_URL}/core/bulk_data/imports/active?${stringify(query)}`)
  },

  getCustomActiveImports: ({ schemaName, tableNames, status }, query = {}) => {
    query = {
      ...query,
      status,
      tableNames: tableNames.join(','),
      schemaName,
      schema: `${schemaName}`,
    }

    return API.get(`${API_URL}/core/bulk_data/imports/active?${stringify(query)}`)
  },

  getRecentExports: ({ schemaName, entityType }, query = {}) => {
    schemaName = entityType === Entity.NOTE ? 'notes' : schemaName

    query = {
      ...query,
      schemaAndTableName: `${schemaName}.${entityType}`,
    }

    return API.get(`${API_URL}/core/bulk_data/exports/recent?${stringify(query)}`)
  },

  getOneExportFile: id => API.get(`${API_URL}/core/bulk_data/exports/download/${id}`),
}
