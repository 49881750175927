import { Entity, GenericTabs, Schema, SYSTEM_SETTINGS } from '@/constants/app'
import { themeColorEnvValues } from '@/constants/theme'

export function markupToText(markup) {
  if (!markup) {
    return null
  }

  const div = document.createElement('DIV')
  div.innerHTML = markup.replace(/<br>/g, '&nbsp;')
  return div.textContent || div.innerText || ''
}

export function downloadFromURL(url, filename) {
  const fileLink = document.createElement('a')
  fileLink.href = url
  fileLink.setAttribute('download', filename)
  document.body.appendChild(fileLink)

  fileLink.click()
}

export function downloadBlob(data, filename) {
  const fileURL = window.URL.createObjectURL(new Blob([data]))
  downloadFromURL(fileURL, filename)
}

export function sortByTrueValue(array, fields) {
  fields = fields.reverse()

  return fields.reduce((sortedArray, field) => {
    // eslint-disable-next-line no-nested-ternary
    sortedArray = sortedArray.sort((x, y) => (x[field] === y[field] ? 0 : x[field] ? -1 : 1))
    return sortedArray
  }, array)
}

export function getDefaultTab(key) {
  const defaultTabs = {
    [Entity.DOCUMENT]: GenericTabs.PREVIEW,
    [SYSTEM_SETTINGS]: GenericTabs.COMPANY_SETTINGS,
  }

  return defaultTabs[key] || GenericTabs.OVERVIEW_360
}

export function setAppTheme() {
  themeColorEnvValues.forEach(({ name, forcedValue, envKey }) => {
    document.documentElement.style.setProperty(name, forcedValue || process.env[envKey])
  })
}

export function swalConfirmDeleteOption($t) {
  return {
    title: $t('Warning'),
    text: $t('Are you sure you want to remove?'),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: $t('Yes'),
    cancelButtonText: $t('No'),
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-outline-primary ml-1',
    },
    buttonsStyling: false,
  }
}

export const fileToDataURL = (file, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result), false)

  if (file) {
    reader.readAsDataURL(file)
  }
}

export function preventSpecialChars(e, options = {}) {
  const pasteVal = e?.clipboardData?.getData('text')
  if (pasteVal) {
    e.preventDefault()

    if (options?.callback) {
      /* allow pasting but remove any special characters */
      options.callback(
        `${e.target.value}${pasteVal
          .toString()
          .trim()
          .replace(/[^\w\s]/gi, '')}`,
      )
    }

    return true
  }

  if (e.keyCode === 13) return true // allow enter to submit form

  const regex = new RegExp(options?.regExp || '^[a-zA-Z0-9_]+$')
  const key = String.fromCharCode(!e.keyCode ? e.which : e.keyCode)
  if (!regex.test(key)) {
    e.preventDefault()
    return false
  }
}
