<template>
  <div class="pr-1">
    <b-row>
      <b-col
        cols="12"
        sm="4"
        class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted">{{ $t('Showing') }} {{ meta.from }} {{ $t('to') }} {{ meta.to }} {{ $t('of') }} {{ meta.of }} {{ $t('entries') }}</span>
      </b-col>
      <b-col
        cols="12"
        sm="8"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
        :class="{ 'pr-0': maxPage > 1 }"
      >
        <b-pagination
          v-if="maxPage > 1"
          :value="page"
          :total-rows="total"
          :per-page="size"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @input="e => $emit('change', e)"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
        <b-button
          v-if="maxPage > 1"
          class="ml-2"
          variant="primary"
          @click="toggleGoToDialog"
        >
          {{ $t('Go to') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    meta: {
      type: Object,
      required: true,
    },

    page: {
      type: [Number, String],
      required: true,
    },

    total: {
      type: [Number, String],
      default: 0,
    },

    size: {
      type: [Number, String],
      required: true,
    },
  },

  computed: {
    maxPage() {
      if (!this.size) {
        return 1
      }

      return Math.ceil(this.total / this.size)
    },
  },

  methods: {
    toggleGoToDialog() {
      this.$swal
        .fire({
          title: this.$t('Go to Page'),
          input: 'number',
          inputValue: null,
          inputPlaceholder: this.$t('Enter page number'),
          inputAttributes: {
            max: this.maxPage,
            width: '200px',
          },
          showCancelButton: true,
          confirmButtonText: this.$t('OK'),
          cancelButtonText: this.$t('Cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-primary ml-1 bg-transparent',
            input: 'tw-w-40 tw-max-w-none text-center',
          },

          inputValidator: value => {
            if (value < 1 || value > this.maxPage) {
              return this.$t('page-number-min-max-helper-text', {
                max: this.maxPage,
              })
            }
          },
        })
        .then(({ isConfirmed, value }) => {
          if (isConfirmed) {
            this.$emit('change', value)
          }
        })
    },
  },
}
</script>
