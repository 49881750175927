import state from '@/store/form/state'
import mutations from '@/store/form/mutations'
import getters from '@/store/form/getters'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
