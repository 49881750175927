import Vue from 'vue'

// axios
import axios from 'axios'

const API = axios.create({
  baseURL: process.env.VUE_APP_PROZESS_API_ENDPOINT,
})

Vue.prototype.$http = API

export default API
