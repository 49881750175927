import API from '@axios'
import { apiHelper } from '../helpers/apiHelper'

const API_URL = '/api/me'

export default {
  ...apiHelper(API_URL),

  getUserInfo() {
    return API.get(`${API_URL}/info`)
  },

  getUserFields() {
    return API.get(`${API_URL}/fields`)
  },
}
