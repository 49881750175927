import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import signant from './app/signant'
import user from './user'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import advancedSearch from '@/store/advanced-search'
import form from '@/store/form'
import dashboard from './dashboard'
import widget from '@/store/widget'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    advancedSearch,
    app,
    appConfig,
    form,
    signant,
    user,
    verticalMenu,
    dashboard,
    widget,
  },

  strict: false,
})
