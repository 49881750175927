import { get } from 'lodash'

export default {
  install(Vue) {
    Vue.prototype.$hasError = function (field, state, errorBag = null) {
      const errors = errorBag ?? this.errors

      if (!errors) {
        return null
      }

      let value = null
      const error = errors.find(err => err.field === field)

      if (error?.defaultMessage) {
        const [message] = error.defaultMessage.split('using format')
        value = message.trim()
      }

      return (state && !!value) ? false : value
    }

    Vue.prototype.$loadErrors = err => {
      const { exception } = err.response.data
      if (exception) return []

      const data = get(err, 'response.data', [])

      if (data.status === 500) return []

      return data.map(error => {
        if (error.objectName === 'form.customFields') {
          error.field = `customFields.${error.field}`
        }
        return error
      })
    }
  },
}
