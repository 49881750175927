export const defaultStateFactory = () => ({
  entities: [],
  selectedEntities: [],
  fieldOptions: [],
  result: {
    allItemsCount: 0,
    filteredItemsCount: 0,
    pageItems: [],
  },
  errors: [],
  dataSourceFormVisible: false,
  dataSourceAdvancedFormVisible: false,
  id: null,
})

export default () => (defaultStateFactory())
