<template>
  <div class="prozess-search-dropdown">
    <div style="flex: 1; display: flex; position: relative; height: 100%">
      <b-input-group
        class="input-group-merge"
        :class="{
          'prozess-search-dropdown__disabled': data !== null || disabled,
        }"
      >
        <template v-if="data !== null">
          <b-input-group-prepend is-text>
            <feather-icon :icon="icon" />
          </b-input-group-prepend>
          <input
            class="form-control"
            :value="displayData"
            :disabled="true"
          />
        </template>
        <template v-else>
          <b-input-group-prepend is-text>
            <feather-icon :icon="icon" />
          </b-input-group-prepend>
          <input
            v-model="searchValue"
            :placeholder="placeholder"
            class="form-control w-full"
            type="text"
            :disabled="disabled"
            @focus="handleFocus"
            @keyup="handleKeyup"
            @blur="handleBlur"
            @keydown="handleKeydown"
          />
        </template>
      </b-input-group>
      <div class="prozess-search-dropdown__new-label">
        <div
          v-if="data && data.isNew && !loading"
          class="prozess-search-dropdown__new-label-text d-flex align-items-center"
        >
          <div>{{ $t(newLabel) }}</div>
        </div>
        <div
          v-show="loading"
          class="dropdown-search-loader"
        >
          <b-spinner />
        </div>
        <div
          v-if="(data !== null || searchValue) && !loading && disabled === false"
          class="clear-button tw-text-muted"
          @click="clear"
        >
          <feather-icon icon="XIcon" />
        </div>
        <div
          v-if="initialOptions.length"
          class="dropdown-icon"
          :class="{
            'dropdown-icon--active': isFocused,
          }"
        >
          <feather-icon icon="ChevronDownIcon" />
        </div>
      </div>
    </div>

    <div
      v-show="showOptions"
      ref="options"
      class="options"
    >
      <ul class="searched-items">
        <li
          v-if="canHandleNewItem && currentlyTyping === false && searchValue !== ''"
          :class="{ selected: selectedItem === null }"
          @mouseover="handleMouseOver"
          @mouseout="handleMouseOut"
          @click="handleNewItem"
        >
          {{ newItemMessage || $t('ADD_NEW_ITEM') }}
        </li>
        <li
          v-for="(option, index) in computedOptions"
          :key="index"
          :data-id="index + 1"
          :class="{
            selected: selectedItem === index + 1,
          }"
          class="d-flex flex-column"
          @mouseover="handleMouseOver"
          @mouseout="handleMouseOut"
          @click="handleOptionClick(option)"
        >
          <span>{{ option.label }}</span>
          <span
            v-for="_label of processAdditionalLabel(option)"
            :key="_label"
            style="font-size: 0.8rem"
          >
            {{ _label }}
          </span>
        </li>

        <p
          v-if="canHandleNewItem === false && currentlyTyping === false && options.length === 0"
          class="no-item-found"
        >
          {{ noItemFoundMessage || $t('No item found') }}
        </p>
      </ul>
    </div>
    <div
      v-if="hint || error"
      class="d-flex justify-content-between mb-2"
    >
      <span
        v-show="error"
        class="invalid-feedback"
        style="flex: 1"
      >
        {{ $t(error) }}
      </span>
      <span
        style="flex: 2"
        class="field-hint"
      >
        {{ $t(hint) }}
      </span>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  props: {
    initialOptions: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    newItemMessage: {
      type: String,
      default: null,
    },
    canHandleNewItem: {
      type: Boolean,
      default: false,
    },
    noItemFoundMessage: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    newLabel: {
      type: String,
      default: 'NEW',
    },
    hint: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentlyTyping: false,
      searchValue: '',
      isFocused: false,
      selectedItem: null,

      data: this.value,
      /*
        Sample data:
        If dropdown item is clicked,
        {
          isNew: false,
          label: 'Apple Inc',
          selectedLabel: 'Customized selected label', // Optional
          value: 12312-12312312-12312
        }
        If the item will be a new one,
        {
          isNew: true,
          label: 'New Item',
          value: 'New Item'
        }
      */
    }
  },
  computed: {
    showOptions() {
      return (
        (this.searchValue.trim() !== '' && this.data === null && this.loading === false && this.currentlyTyping === false) ||
        (this.initialOptions.length && this.searchValue === '' && this.isFocused)
      )
    },
    computedOptions() {
      if (this.isFocused && this.initialOptions.length && this.searchValue === '') {
        return this.initialOptions
      }
      return this.options
    },
    displayData() {
      return this.data.isNew ? this.data.value : this.data.selectedLabel || this.data.label
    },
  },
  watch: {
    value(data) {
      this.data = data
      if (this.data === null) {
        this.searchValue = ''
      }
    },
    searchValue(search) {
      this.currentlyTyping = true
      this.selectedItem = null

      if (search.trim() === '' || search.trim().length < 3) {
        this.showNoItemFound = false
        this.$emit('clear')
        return
      }

      if (this.data !== null) return

      this.data = null
      this.handleSearch()
    },
    options() {
      if (this.options.length > 0) {
        this.selectedItem = 1
      }
    },
  },
  methods: {
    processAdditionalLabel(option) {
      const labels = option.additionalLabels || []
      return labels.reduce((acc, label) => {
        if (label.includes('.')) {
          const stages = label.split('.')
          let temp = option.value[stages[0]].map(item => item[stages[1]])
          // remove duplicates
          temp = temp.filter((item, pos) => temp.indexOf(item) === pos)
          // Max of 2 names
          if (temp.length > 2) {
            temp = [temp[0], temp[1], `(+${temp.length - 2})`]
          }
          temp = temp.join(', ')
          if (temp !== '') {
            acc.push(temp)
          }
        } else {
          acc.push(option.value[label])
        }
        return acc
      }, [])
    },
    handleKeydown(e) {
      if (e.key === 'Enter') {
        e.preventDefault()
        return false
      }
    },
    handleMouseOver($event) {
      const element = $event.target.tagName === 'SPAN' ? $event.target.parentElement : $event.srcElement
      element.classList.add('selected')
      this.selectedItem = element.getAttribute('data-id')
    },
    handleMouseOut($event) {
      const element = $event.target.tagName === 'SPAN' ? $event.target.parentElement : $event.srcElement
      element.classList.remove('selected')
    },
    autoChooseItem() {
      if (this.searchValue.trim() === '' && this.initialOptions.length === 0) return
      if (this.data !== null) return

      if (this.selectedItem === null && this.searchValue.trim() !== '') {
        this.handleNewItem()
      } else {
        if (this.computedOptions.length === 0) return
        this.handleOptionClick(this.computedOptions[this.selectedItem - 1])
      }
    },
    handleKeyup(e) {
      e.preventDefault()

      // Escape key
      if (e.keyCode === 27) {
        this.clear()
        return
      }

      if (this.computedOptions.length === 0) {
        this.selectedItem === null
      }
      if (e.keyCode === 13) {
        this.autoChooseItem()
        return
      }

      this.$refs.options.children[0].children.forEach(element => {
        element.classList.remove('selected')
      })

      // Arrow down
      if (e.keyCode === 40) {
        if (this.selectedItem === null && this.computedOptions.length) {
          this.selectedItem = 1
          return
        }
        if (this.selectedItem === this.computedOptions.length) {
          this.selectedItem = null
          return
        }
        if (this.selectedItem < this.computedOptions.length) {
          this.selectedItem++
        }
      }
      // Arrow up
      if (e.keyCode === 38) {
        if (this.selectedItem === null) {
          if (this.computedOptions.length) {
            this.selectedItem = this.computedOptions.length
            return
          }
        }
        if (this.selectedItem === 1) {
          this.selectedItem = null
          return
        }
        if (this.selectedItem > 0) {
          this.selectedItem--
        }
      }
    },
    handleFocus() {
      this.isFocused = true

      if (this.initialOptions.length > 0) {
        this.selectedItem = 1
      }
    },
    handleBlur() {
      setTimeout(() => {
        this.autoChooseItem()
        this.isFocused = false
        this.selectedItem = null
        this.$emit('blur')
      }, 200)
    },
    handleOptionClick(value) {
      this.data = {
        isNew: false,
        ...value,
      }
      this.searchValue = value.label
      this.$emit('input', this.data)
    },
    handleNewItem() {
      if (this.canHandleNewItem === false) {
        this.searchValue = ''
        this.data = null
        return
      }
      this.data = {
        isNew: true,
        label: this.searchValue,
        value: this.searchValue,
      }
      this.$emit('input', this.data)
    },
    clear() {
      this.data = null
      this.searchValue = ''
      this.selectedItem = null
      this.$emit('clear')
    },
    handleSearch: debounce(function () {
      if (this.data !== null) return

      this.currentlyTyping = false
      this.$emit('search', this.searchValue)
    }, 1000),
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@/assets/scss/master-variables.scss';

.prozess-search-dropdown {
  position: relative;

  &__disabled {
    div.input-group-prepend > div.input-group-text {
      opacity: 0.5;
      margin-right: 1px;
    }
  }

  &__new-label {
    display: flex;
    position: absolute;
    right: 0;
    top: 0px;
    bottom: 0px;
    z-index: 10;
  }

  &__input {
    width: 100%;

    input {
      padding-right: 7rem;
    }
  }

  &__new-label-text {
    margin-right: 0.5rem;
    // right: 2.5rem;
    // position: absolute;
    // z-index: 10;
    // height: 100%;
    color: $colour--white;

    div {
      background: $colour--text-muted;
      padding: 0.3rem 0.6rem;
      border-radius: 1rem;
      font-size: 0.8rem;
      font-weight: bold;
    }
  }
  .clear-button {
    display: flex;
    align-items: center;
    height: 100%;
    z-index: 10;
    cursor: pointer;
    svg {
      margin-right: 0.5rem;
    }
  }
  .dropdown-icon {
    display: flex;
    align-items: center;
    height: 100%;
    z-index: 10;
    cursor: pointer;
    svg {
      margin-right: 0.5rem;
      width: 20px;
      height: 20px;
      transform: rotate(0deg);
      transition: transform 0.2s;
    }
    &--active {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .options {
    position: absolute;
    z-index: 200;
    min-width: 160px;
    width: 100%;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      background: var(--colour--input);
      box-shadow: $colour--box-shadow;
      border: 1px solid var(--colour--input-border);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;

      li {
        position: relative;
        padding: 10px;
        cursor: pointer;
        user-select: none;
        color: $colour--dropdown-item;
      }
      li.selected {
        color: $colour--white;
      }
    }
  }
  .options {
    max-height: 320px;
    overflow-y: scroll;
  }
}

.dropdown-search-loader {
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 100%;
  width: 34px;
  background: transparent;
  margin-right: 5px;
  .vs-con-loading__container {
    height: 34px;
    width: 34px;
  }
}
.searched-items {
  .no-item-found {
    text-align: center;
    padding: 10px;
    color: $colour--dropdown-item;
  }
  .selected {
    background: var(--colour--primary);
  }
}
</style>
