<template>
  <div class="prozess-input">
    <v-select
      :id="id"
      v-model="currentValue"
      v-bind="{ ...$props, ...$attrs }"
      :append-to-body="appendToBody"
      :calculate-position="withPopper"
      @input="handleInput"
      @search:focus="showLabel = true"
      @search:blur="showLabel = false"
      @option:selecting="e => $emit('selecting', e)"
      @option:selected="e => $emit('selected', e)"
    >
      <span slot="no-options">
        {{ $t('Sorry, no matching options') }}
      </span>
      <template
        v-for="(_, slot) of $scopedSlots"
        #[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        ></slot>
      </template>
    </v-select>
    <label
      :for="id"
      class="input__label label--indented"
      :class="{ input__label_focus: showLabel || (Array.isArray(currentValue) ? currentValue.length : !!currentValue) }"
    >
      {{ $attrs.placeholder }}
    </label>
    <div
      v-if="hint || error"
      class="d-flex justify-content-between mb-2"
    >
      <span
        v-show="error"
        class="invalid-feedback"
      >
        {{ $t(error) }}
      </span>
      <span class="field-hint">{{ $t(hint) }}</span>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { createPopper } from '@popperjs/core'

export default {
  components: {
    vSelect,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      default: null,
    },

    appendToBody: {
      type: Boolean,
      default: true,
    },

    hint: {
      type: String,
      default: null,
    },

    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentValue: null,
      showLabel: false,
    }
  },
  computed: {
    id() {
      return this.$attrs.id || this.$case.snake(`${this.$attrs.placeholder} ${this.$randStr(4)}`)
    },
  },
  watch: {
    value: {
      handler(newValue, oldValue) {
        this.currentValue = newValue
      },
      immediate: true,
    },
  },
  methods: {
    handleInput() {
      this.$emit('input', this.currentValue)
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offsets.place',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            },
          },
        ],
      })

      return () => popper.destroy()
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/master-variables.scss';

[data-popper-placement='bottom'] {
  z-index: 9999999 !important;
}
[data-popper-placement='top'] {
  z-index: 9999999 !important;
}
.prozess-input {
  &__disabled,
  &[disabled='disabled'] {
    div.input-group > div.input-group-prepend {
      opacity: 0.5;
      div.input-group-text {
        margin-right: 1px;
      }
    }
  }

  input::placeholder {
    color: transparent !important;
  }

  .input__label {
    position: absolute;
    top: 0.72rem;
    left: 1rem;
    font-size: 11px;
    color: var(--colour--input-placeholder);
    transition: all 0.15s ease-in-out;

    &.label--indented {
      left: 4rem;
    }
  }

  input:focus,
  input:not(:placeholder-shown) {
    .input__label {
      top: -1.45rem;
      left: 0;
      font-size: 10px;
      color: var(--colour--text-primary);
    }
  }
}

.input__label_focus {
  top: -1.45rem !important;
  left: 0 !important;
  font-size: 10px;
  color: var(--colour--text-primary) !important;
}
</style>
