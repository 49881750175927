import { Join, AdvancedSearchCondition, nullableConditions } from '@/constants/advancedSearch'
import { Schema } from '@/constants/app'
import { cloneDeep } from 'lodash'

function fieldOptionStatusCount(fieldOptions, statusKey) {
  return fieldOptions
    .reduce((total, { fields }) => {
      total += fields.filter(field => field[statusKey]).length
      return total
    }, 0)
}

export default {
  primaryEntityId(state) {
    return state.selectedEntities[0]
  },

  filterOptions(state) {
    const fieldOptions = cloneDeep(state.fieldOptions)

    fieldOptions.forEach(({ fields }, i) => {
      fieldOptions[i].fields = fields.filter(field => field.filtering)
    })

    return fieldOptions.filter(({ fields }) => fields.length)
  },

  selectedTablesCount(state) {
    return state.selectedEntities.length
  },

  selectedFieldsCount(state) {
    return fieldOptionStatusCount(state.fieldOptions, 'selected')
  },

  selectedFiltersCount(state) {
    return fieldOptionStatusCount(state.fieldOptions, 'filtering')
  },

  filteringFields(state) {
    return state.fieldOptions
      .reduce((filteringFields, { fields }) => {
        filteringFields.push(...fields.filter(field => field.filtering))
        return filteringFields
      }, [])
  },

  searchModel(state) {
    const isCustomField = field => (field.entitySchema === Schema.CUSTOM ? false : !!field.id)

    const entities = state.fieldOptions.map(option => ({
      schemaName: option.schema,
      tableName: option.key,

      selectedFields: option.fields.reduce((selectedFields, field) => {
        if (field.selected) {
          selectedFields.push({
            key: field.key,
            customField: isCustomField(field),
            query: field.query,
          })
        }

        return selectedFields
      }, []),

      filters: option.fields.reduce((filters, field) => {
        if (field.filtering) {
          const filter = {
            field: {
              key: field.key,
              customField: isCustomField(field),
            },
            operation: AdvancedSearchCondition[field.filterCondition],
            param: { value: field.filterValue },
          }

          if (nullableConditions.includes(AdvancedSearchCondition[field.filterCondition])) {
            delete filter.param
          }

          filters.push(filter)
        }

        return filters
      }, []),
    }))

    let [baseTable] = state.fieldOptions

    const joins = state.fieldOptions.map(option => {
      const join = {
        schema1Name: baseTable.schema,
        table1Name: baseTable.key,
        joinType: Join.INNER,
        schema2Name: option.schema,
        table2Name: option.key,
      }

      baseTable = option

      return join
    })

    joins.shift()

    return { entities, joins }
  },
}
