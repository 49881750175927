<template>
  <div class="pb-1">
    <div
      v-for="(transaction, index) of historyWithUuid"
      :key="transaction._uuid"
      class="resource-view__history-row px-2 py-1 mb-1 d-flex justify-content-between"
    >
      <div
        class="d-flex flex-column resource-view__history-row-text"
        :class="{
          'resource-view__history-row-text--read-more': !transaction.hide
        }"
      >
        <span v-html="$displayTransaction(transaction)" />
        <div v-if="transaction.interpretation.text.length > 514">
          <button
            class="btn btn-primary btn-sm mt-1"
            @click="$set(history, index, {
              ...transaction,
              hide: !transaction.hide
            })"
          >
            {{ $t('Read More ') }}
          </button>
        </div>
      </div>
      <span class="resource-view__history-row-timestamp">{{ $d(new Date(transaction.timestamp), 'long') }}</span>
    </div>
    <p v-if="!loading && history.length === 0">
      {{ $t('No history yet') }}
    </p>
    <div
      v-if="!loading "
      class="d-flex justify-content-end"
    >
      <b-pagination
        :value="historyPagination.currentPage"
        :total-rows="historyPagination.total"
        :per-page="historyPagination.perPage"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        @input="handleCurrentPage"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    historyPagination: {
      type: Object,
      required: true,
    },
    history: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    historyWithUuid() {
      return this.history.map(item => ({
        ...item,
        _uuid: this.$uuidv4(),
      }))
    },
  },
  methods: {
    handleCurrentPage(value) {
      this.$emit('handleCurrentPage', value)
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/resource-history.scss";
</style>
