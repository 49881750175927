import Vue from 'vue'
import VueMq from 'vue-mq'

export const register = () => {
  Vue.use(VueMq, {
    breakpoints: {
      xs: 576,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: Infinity,
    },
    defaultBreakpoint: 'sm',
  })

  const mqExtraLarge = ['xl']
  const mqLarge = ['lg', ...mqExtraLarge]
  const mqMedium = ['md', ...mqLarge]
  const mqSmall = ['sm', ...mqMedium]
  const mqExtraSmall = ['xs', ...mqSmall]

  Vue.prototype.$mqExtraSmall = mqExtraSmall
  Vue.prototype.$mqSmall = mqSmall
  Vue.prototype.$mqMedium = mqMedium
  Vue.prototype.$mqLarge = mqLarge
  Vue.prototype.$mqExtraLarge = mqExtraLarge

  Vue.mixin({
    computed: {
      mqExtraSmall() {
        return this.$mqExtraSmall.includes(this.$mq)
      },

      mqSmall() {
        return this.$mqSmall.includes(this.$mq)
      },

      mqMedium() {
        return this.$mqMedium.includes(this.$mq)
      },

      mqLarge() {
        return this.$mqLarge.includes(this.$mq)
      },

      mqExtraLarge() {
        return this.$mqExtraLarge.includes(this.$mq)
      },
    },
  })
}
