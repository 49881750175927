import { renameKeys } from 'lodash-contrib'
import { advancedFieldValue } from '@/helpers/customFields'
import { dateTimeFields } from '@/constants/customFieldTypes'

export default {
  computed: {
    getSchemaTableName() {
      const schemaTableName = {
        schemaKey: '',
        tableKey: '',
      }

      const pageName = this.$route.name
      if (pageName.includes('compan')) {
        schemaTableName.tableKey = 'company'
        schemaTableName.schemaKey = 'crm'
      }

      if (pageName.includes('note')) {
        schemaTableName.tableKey = 'note'
        schemaTableName.schemaKey = 'notes'
      }

      if (pageName.includes('contact')) {
        schemaTableName.tableKey = 'contact'
        schemaTableName.schemaKey = 'crm'
      }

      if (pageName.includes('document')) {
        schemaTableName.tableKey = 'document'
        schemaTableName.schemaKey = 'documents'
      }

      if (pageName.includes('custom-entity')) {
        schemaTableName.tableKey = this.$route.params.key
        schemaTableName.schemaKey = 'custom_schema'
      }

      if (pageName.includes('inquir')) {
        schemaTableName.tableKey = 'inquiry'
        schemaTableName.schemaKey = 'core'
      }
      if (pageName.includes('todo')) {
        schemaTableName.tableKey = 'todo'
        schemaTableName.schemaKey = 'core'
      }
      return schemaTableName
    },

  },

  methods: {
    camelize(val) {
      return val.replace(/_./g, x => x[1].toUpperCase())
    },
  },
}

export const customFieldsMixins = {
  computed: {
    getStandardFields() {
      const filteredStandard = this.fieldMetadata.filter(meta => !meta.customField).reduce((acc, field) => ({ ...acc, [this.camelize(field.key)]: field.key }), {})
      return { ...this.standardKeyMapping, ...filteredStandard }
    },

  },

  methods: {
    formatAsFormData(response, customFieldName = 'customFieldDataResponses') {
      const customFields = response[customFieldName].reduce((acc, item) => {
        acc[item.key] = advancedFieldValue(item.value, {
          toArrayIfMulti: true,
        })
        return acc
      }, {})

      const form = this.$lodash.pick(response, Object.keys(this.getStandardFields))

      this.form = {
        ...this.form,
        ...renameKeys(form, this.getStandardFields),
        ...customFields,
      }
    },
    formatAsPayload() {
      const form = this.$lodash.cloneDeep(this.form)

      const standardFields = this.$lodash.pick(form, Object.values(this.getStandardFields))

      return {
        ...renameKeys(standardFields, this.$lodash.invert(this.getStandardFields)),
        customFields: this.fieldMetadata.reduce((acc, field) => {
          if (field.customField) {
            acc[field.key] = field.type === 'DATE' && !form[field.key] ? null : form[field.key]
          }
          return acc
        }, {}),
      }
    },

    makeRequest(payload) {
      return this.resourceId ? this.serviceName.patch(this.resourceId, payload) : this.serviceName.store(payload)
    },

    isFieldDateTime(field) {
      return dateTimeFields.includes(field)
    },
  },

}
