export const FieldType = {
  QUESTION: 'question',
  TITLE: 'title',
}

export const FormAction = {
  CREATE: 'create',
  VIEW: 'view',
  EDIT: 'edit',
}
