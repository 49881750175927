export default {
  methods: {
    getAppInitialData() {
      return Promise.all([
        this.$store.dispatch('signant/fetchSignantStatus'),
        this.$getUserAndPermissions(),
      ])
    },
  },
}
