<template>
  <div
    v-if="$can('Read', 'Entity_Assignment') && isMainEntity"
    class="notification-container"
  >
    <span class="text-muted notification-container__header-title">
      {{ $t('This section is to notify the added users/groups of changes made on this record.') }}
    </span>
    <b-input-group
      v-if="$can('Create', 'Entity_Assignment') && !isViewing"
      class="input-group-merge form-sidebar__add-user"
    >
      <b-input-group-prepend is-text>
        <feather-icon icon="UserPlusIcon" />
      </b-input-group-prepend>
      <v-select
        v-model="selectedUser"
        style="flex: 1"
        :placeholder="$t('Add User')"
        :options="users"
        :get-option-label="(option) => option.firstName + ' ' + option.lastName"
        @input="selectUser"
        @search="onSearchUser"
        @search:blur="onBlurSearchUser"
      >
        <span slot="no-options">
          <span v-if="searchUserText.length">{{ $t(`No results found`) }}</span>
          <span v-else>{{ $t(`Search by name`) }}</span>
        </span>
      </v-select>
    </b-input-group>
    <div class="form-sidebar__users tw-pt-3">
      <div class="p-1 form-sidebar__users-header">
        <h3>{{ $t('Users') }}</h3>
      </div>
      <div class="form-sidebar__user-list text-muted">
        <div v-if="selectedUsers.length === 0" class="p-1">
          {{ $t('No users.') }}
        </div>

        <div
          v-for="user of selectedUsers"
          :key="user.userUuid"
          class="form-sidebar__user p-1 d-flex justify-content-between"
        >
          <div>
            <p>{{ user.name }}</p>
            <b-input-group
              v-if="isCompany"
              class="input-group-merge form-sidebar__add-user"
              style="width: 200px"
            >
              <category-company-contact-search
                :is-view-mode="isViewing"
                icon="GridIcon"
                label="Set user category"
                entity="category"
                :uuid-key="user.userUuid"
                :value="user.category"
                :disabled="user.disabled"
                @input="selectCategory"
              />
            </b-input-group>
          </div>
          <div
            v-if="$can('Delete', 'Entity_Assignment') && !isViewing"
            @click="removeUser(user)"
          >
            <feather-icon
              icon="XIcon"
              class="text-white tw-cursor-pointer bg-primary tw-rounded-full tw-flex tw-justify-center tw-items-center"
              style="padding: 2px"
              size="16"
            />
          </div>
        </div>
      </div>
    </div>

    <b-input-group
      v-if="$can('Create', 'Entity_Assignment') && !isViewing"
      class="input-group-merge form-sidebar__add-user"
    >
      <b-input-group-prepend is-text>
        <feather-icon icon="UserPlusIcon" />
      </b-input-group-prepend>
      <v-select
        v-model="selectedGroup"
        style="flex: 1"
        :placeholder="$t('Add Group')"
        :options="groups"
        :get-option-label="(option) => option.name"
        @input="selectGroup"
        @search="onSearchGroup"
        @search:blur="onBlurSearchGroup"
      >
        <span slot="no-options">
          <span v-if="searchGroupText.length">{{
            $t(`No results found`)
          }}</span>
          <span v-else>{{ $t(`Search by name`) }}</span>
        </span>
      </v-select>
    </b-input-group>

    <div class="form-sidebar__users tw-pt-3 text-muted">
      <div class="p-1 form-sidebar__users-header">
        <h3>{{ $t('Groups') }}</h3>
      </div>
      <div class="form-sidebar__user-list">
        <div v-if="selectedGroups.length === 0" class="p-1">
          {{ $t('No groups.') }}
        </div>
        <div
          v-for="group of selectedGroups"
          :key="group.groupUuid"
          class="form-sidebar__user p-1 d-flex justify-content-between"
        >
          <p>{{ group.name }}</p>
          <div
            v-if="$can('Delete', 'Entity_Assignment') && !isViewing"
            @click="removeGroup(group)"
          >
            <feather-icon
              icon="XIcon"
              class="text-white tw-cursor-pointer bg-primary tw-rounded-full tw-flex tw-justify-center tw-items-center"
              style="padding: 2px"
              size="16"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { debounce } from 'lodash'
import employeeService from '@/services/employee'
import groupService from '@/services/group'
import fieldMixins from '@/mixins/fields'
import CategoryCompanyContactSearch from '@/views/pages/Companies/CategoryCompanyContactSearch.vue'

export default {
  components: { vSelect, CategoryCompanyContactSearch },
  mixins: [fieldMixins],
  props: {
    id: {
      type: String,
      default: null,
    },

    isViewing: {
      type: Boolean,
      default: false,
    },

    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      users: [],
      groups: [],
      selectedUser: null,
      selectedGroup: null,
      selectedUsers: [],
      selectedGroups: [],
      searchUserText: '',
      searchGroupText: '',
      userCategoryAssignFormList: [],
      isCompany: false,
      savedUsers: [],
    }
  },

  computed: {
    isMainEntity() {
      return !this.$route.params.id || this.id === this.$route.params.id
    },

    assignToEntities() {
      return [
        {
          ...this.getSchemaTableName,
          uuidKey: this.id,
        },
      ]
    },
  },

  watch: {
    id: {
      handler(val) {
        if (val) this.fetchAssignedEntities()
      },
      immediate: true,
    },

    '$route.name': {
      handler(value) {
        this.isCompany = (value === 'companies' || value === 'company-view')
      },
      immediate: true,
    },

    visible: {
      async handler(visible) {
        if (visible) {
          this.reset()
        }
      },

      immediate: true,
    },
  },

  methods: {
    onBlurSearchUser() {
      this.users = []
    },

    reset() {
      this.userCategoryAssignFormList = []
      this.selectedUsers = []
      this.selectedGroups = []
    },

    async fetchAssignedEntities() {
      const id = this.id
      const names = this.getSchemaTableName
      const groupResponse = await this.$async(
        groupService.getAssignedGroups(names.schemaKey, names.tableKey, id),
      )

      const userResponse = await this.$async(
        employeeService.getAssignedGroups(names.schemaKey, names.tableKey, id),
      )

      const users = userResponse.response.data.map(user => {
        const item = user.category
          ? {
            ...user,
            userUuid: user.uuid,
            category: {
              label: user.category?.metaStatusName,
              categoryId: user.category?.metaStatusId,
            },
            disabled: true,
          }
          : {
            ...user,
            userUuid: user.uuid,
            disabled: false,
          }
        return item
      })

      const groups = groupResponse.response.data.map(user => ({
        ...user,
        groupUuid: user.uuid,
      }))
      this.savedUsers = userResponse?.response?.data
      this.selectedUsers = users
      this.selectedGroups = groups
    },

    async selectCategory(user, category) {
      if (category?.categoryId) {
        return this.$emit('categoryAdded', user, category.categoryId)
      }
    },

    async selectUser(user) {
      user.name = `${user.firstName} ${user.lastName}`
      this.searchUserText = ''
      this.selectedUser = null
      const isExisting = this.selectedUsers.find(
        userFInd => userFInd.userUuid === user.userUuid,
      )

      if (isExisting) return this.showWarning('User already exist')

      if (!this.id || this.isCompany) {
        this.selectedUsers.push(user)
        return this.$emit('userAdded', user.userUuid)
      }

      const response = await this.$async(
        employeeService.assignedToEntities(user.userUuid, {
          assignToEntities: this.assignToEntities,
        }),
      )
      if (response.error) return false

      this.selectedUsers.push(user)
      this.$emit('saved')
    },

    async removeUser(user) {
      // const intialUser = this.savedUsers.findIndex(item => item.uuid === user.userUuid)
      // if (!this.id || intialUser === -1) {
      //   this.selectedUsers = this.selectedUsers.filter(
      //     userFilter => userFilter.userUuid !== user.userUuid,
      //   )
      //   return this.$emit('userRemoved', user.userUuid)
      // }

      const { error } = await this.$async(
        employeeService.deleteAssignedGroup(user.userUuid, {
          removeFromEntities: this.assignToEntities,
        }),
      )

      if (error) return this.showWarning(error.response.data.message)
      this.selectedUsers = this.selectedUsers.filter(
        userFilter => userFilter.userUuid !== user.userUuid,
      )

      this.savedUsers = this.savedUsers.filter(
        userFilter => userFilter.uuid !== user.userUuid,
      )

      this.$emit('saved')
    },

    onSearchUser(search, loading) {
      this.searchUserText = search
      if (search.length) {
        loading(true)
        this.searchUser(search, loading, this)
      }
    },

    searchUser: debounce(async (search, loading, vm) => {
      const res = await employeeService.searchEmployee(search)
      vm.users = res.data
      loading(false)
    }, 500),

    onBlurSearchGroup() {
      this.groups = []
    },

    async selectGroup(group) {
      this.searchGroupText = ''
      this.selectedGroup = null
      const isExisting = this.selectedGroups.find(
        groupFind => groupFind.groupUuid === group.groupUuid,
      )
      if (isExisting) return this.showWarning('Group already exist')

      if (!this.id) {
        this.selectedGroups.push(group)
        return this.$emit('groupAdded', group.groupUuid)
      }

      const response = await this.$async(
        groupService.assignedToEntities(group.groupUuid, {
          assignToEntities: this.assignToEntities,
        }),
      )

      if (response.error) return false
      this.selectedGroups.push(group)

      this.$emit('saved')
    },

    async removeGroup(group) {
      if (!this.id) {
        this.selectedGroups = this.selectedGroups.filter(
          groupFilter => groupFilter.groupUuid !== group.groupUuid,
        )
        return this.$emit('groupRemoved', group.groupUuid)
      }

      const { error } = await this.$async(
        groupService.deleteAssignedGroup(group.groupUuid, {
          removeFromEntities: this.assignToEntities,
        }),
      )

      if (error) return this.showWarning(error.response.data.message)
      this.selectedGroups = this.selectedGroups.filter(
        groupFilter => groupFilter.groupUuid !== group.groupUuid,
      )

      this.$emit('saved')
    },

    onSearchGroup(search, loading) {
      this.searchGroupText = search
      if (search.length) {
        loading(true)
        this.searchGroup(search, loading, this)
      }
    },

    searchGroup: debounce(async (search, loading, vm) => {
      const res = await groupService.searchGroup(search)
      vm.groups = res.data
      loading(false)
    }, 500),
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/form-sidebar.scss';
@import '@/assets/scss/master-variables.scss';
.notification-container {
  display: flex;
  color: var(--colour-text-muted);
  flex-direction: column;
  border-top: 1px dashed var(--colour--input-border);
  margin: 20px auto;
  > * {
    width: 100%;
    margin: 0.5rem auto;
  }
  &__header-title {
    white-space: normal !important;
  }
}
</style>
