export default {
  updateSelectedWidget(state, payload) {
    state.selectedWidget = payload
  },

  setColumns(state, payload) {
    state.columns = payload
  },

  updateWidgetValue(state, payload) {
    state.widgetValue = payload
  },

  SET_BASE_DATA(state, payload) {
    state.baseData = payload
  },

  setChartConfig(state, payload) {
    state.chartConfig = payload
  },

  setStatus(state, payload) {
    state.isSaved = payload.val
    state.id = payload.id
  },

  setChartData(state, payload) {
    state.isPreviewing = true
  },

  setWidgetData(state, payload) {
    state.columns = payload.columns
  },

  setPreviewData(state, payload) {
    state.previewData = payload
  },

  resetForm(state, payload) {
    state.selectedWidget = null
    state.columns = []
    state.widgetValue = {
      type: '',
      description: '',
    }
    state.previewData = ''
    state.tableData = []
    state.isSaved = false
    state.isPreviewing = false
    state.baseData = {}
    state.chartConfig = {}
    state.errors = []
    state.id = null
  },

  setPreviewUserFilterValues(state, payload) {
    state.userFilterValues = payload.map(val => ({
      field: val.fieldInfo,
      value: val.val,
    }))
  },

  setWidgetConfiguration(state, payload) {
    state.widgetConfiguration = payload
  },
}
