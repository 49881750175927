export function getInfoIcons(key) {
  switch (key) {
    case 'companyName':
      return 'BriefcaseIcon'
    case 'contact':
      return 'UserIcon'
    case 'statusName':
      return 'ActivityIcon'
    case 'priorityName':
      return 'ChevronsUpIcon'
    case 'categoryName':
      return 'GridIcon'
    case 'sourceName':
      return 'DatabaseIcon'
    case 'assignedUserName':
      return 'UserCheckIcon'
    case 'received_date':
      return 'CalendarIcon'
    case 'description':
      return 'AlignLeftIcon'
    case 'due_date':
      return 'CalendarIcon'
    default:
      return 'TypeIcon'
  }
}
