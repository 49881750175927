import API from '@axios'
import { stringify as toQuery } from 'query-string'
import { apiHelper } from '../helpers/apiHelper'

const API_URL = '/api/core/groups'

export default {
  ...apiHelper(API_URL),
  searchGroup(query) {
    return API.get(`${API_URL}/autocomplete?${toQuery({
      query,
    })}`)
  },

  getAssignedGroups(schemaName, tableName, id) {
    return API.get(`${API_URL}/${schemaName}/${tableName}/${id}`)
  },
  assignedToEntities(groupId, payload) {
    return API.post(`${API_URL}/${groupId}/assign_to_entities`, payload)
  },

  deleteAssignedGroup(id, data) {
    return API.delete(`${API_URL}/${id}/remove_from_entities`, { data })
  },
}
