<template>
  <div tabindex="0" @keyup.esc="$emit('close')">
    <b-sidebar
      id="form-sidebar"
      :visible="visible"
      bg-variant="white"
      no-close-on-backdrop
      no-close-on-esc
      no-header
      right
      backdrop
      shadow
    >
      <div class="form-sidebar d-flex flex-column">
        <div
          class="form-sidebar__header d-flex justify-content-between align-items-center p-1"
        >
          <div class="d-flex align-items-center">
            <feather-icon :icon="getFormIcon()" />
            <h1 class="form-sidebar__header-title m-0 ml-1 text-uppercase">
              {{ $t(getFormTitle()) }}
            </h1>
          </div>
          <button
            class="btn btn-close btn-icon rounded-circle"
            @click="$emit('close')"
          >
            <feather-icon icon="XIcon" class="tw-text-white" />
          </button>
        </div>
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          rounded="sm"
          variant="transparent"
          class="d-flex flex-column"
          style="flex: 1"
        >
          <slot />
          <div
            v-if="!isViewOnly"
            class="form-sidebar__footer d-flex justify-content-around align-items-center p-1"
          >
            <button
              class="btn btn-primary mr-1 d-flex justify-content-center align-items-center"
              type="submit"
              :disabled="saving || savingDisabled"
              @click="$emit('submit')"
            >
              <b-spinner v-if="saving" small class="mr-1" />
              <feather-icon v-else :icon="getSaveIcon()" class="mr-50" />
              <span>{{ $t(getSaveLabel()) }}</span>
            </button>
            <b-button variant="outline-primary" @click="$emit('close')">
              {{ $t('Cancel') }}
            </b-button>
          </div>
          <div
            v-else
            class="form-sidebar__footer d-flex align-items-center p-1"
          >
            <b-button variant="outline-primary" @click="$emit('close')">
              {{ $t('Close') }}
            </b-button>
          </div>
        </b-overlay>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    editing: {
      type: Boolean,
      default: false,
    },

    isViewOnly: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    saving: {
      type: Boolean,
      default: false,
    },

    savingDisabled: {
      type: Boolean,
      default: false,
    },

    formIcon: {
      type: String,
      default: '',
    },

    formTitle: {
      type: String,
      default: null,
    },

    saveIcon: {
      type: String,
      default: '',
    },

    saveLabel: {
      type: String,
      default() {
        return 'Save'
      },
    },
  },

  methods: {
    getSaveIcon() {
      if (this.saveIcon) {
        return this.saveIcon
      }

      return this.editing ? '' : 'SaveIcon'
    },

    getSaveLabel() {
      if (this.saveLabel) {
        return this.saveLabel
      }

      return this.editing ? 'Save Changes' : 'Save'
    },

    getFormTitle() {
      if (this.formTitle) {
        return this.formTitle
      }

      return this.editing ? 'Edit' : 'Add New'
    },

    getFormIcon() {
      if (this.formIcon) {
        return this.formIcon
      }

      return this.editing ? 'EditIcon' : 'PlusIcon'
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/form-sidebar.scss';
</style>
