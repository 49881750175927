import Vue from 'vue'
import lodash from 'lodash'
import { v4 } from 'uuid'
import dayjs from 'dayjs'
import Case from 'case'
import * as yup from 'yup'
import to from 'await-to-js'
import { sortByFavorite, formatForResource } from '@/helpers/customFields'

const LocalizedFormat = require('dayjs/plugin/localizedFormat')
const relativeTime = require('dayjs/plugin/relativeTime')

dayjs.extend(LocalizedFormat)
dayjs.extend(relativeTime)

Vue.prototype.$lodash = lodash
Vue.prototype.$optional = lodash.get
Vue.prototype.$dayjs = dayjs
Vue.prototype.$uuidv4 = v4
Vue.prototype.$case = Case
Vue.prototype.$randStr = length => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random()
      * charactersLength))
  }
  return result
}

Vue.prototype.$async = async promise => {
  const [error, response] = await to(promise)
  return { error, response }
}

yup.setLocale({
  mixed: {
    required: 'Required',
  },
  string: {
    email: 'Must be an email',
    url: 'Must be a valid URL',
  },
  array: {
    min: 'Required',
  },
})

Vue.prototype.$yup = yup

Vue.prototype.$customFields = {
  sort: sortByFavorite,
  formatForResource,
}

Vue.prototype.$tick = (callback, delay = 0) => setTimeout(callback, delay)

Vue.prototype.$blobify = (data, config = null) => new Blob([JSON.stringify(data)], config ?? {
  type: 'application/json',
})
