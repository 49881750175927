import signatureService from '@/services/signature'

export default {
  namespaced: true,
  state: {
    isSignantEnabled: false,
  },
  getters: {
  },
  mutations: {
    SET_IS_SIGNANT_ENABLED(state, value) {
      state.isSignantEnabled = value
    },
  },
  actions: {
    fetchSignantStatus({ commit }) {
      return signatureService.isEnabled()
        .then(res => {
          commit('SET_IS_SIGNANT_ENABLED', res.data)
        })
    },
    setIsSignantEnabled({ commit }, { hasDistributorIdAndAccessCode, value }) {
      if (hasDistributorIdAndAccessCode) {
        signatureService.toggle(value)
      }
      commit('SET_IS_SIGNANT_ENABLED', value)
    },
  },
}
