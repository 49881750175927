import state from '@/store/dashboard/state'
import mutations from '@/store/dashboard/mutations'
import getters from '@/store/dashboard/getters'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
