export function findSelectedFieldIndices(state, { entityId, fieldKey }) {
  const { fieldOptions } = state

  const optionIndex = fieldOptions.findIndex(_option => _option.entityId === entityId)
  const { fields } = fieldOptions[optionIndex]

  const fieldIndex = fields.findIndex(_field => _field.key === fieldKey)

  return {
    optionIndex,
    fieldIndex,
  }
}

export function findOptionIndex(state, entityId) {
  const optionIndex = state.fieldOptions.findIndex(_option => _option.entityId === entityId)
  return {
    option: state.fieldOptions[optionIndex],
    index: optionIndex,
  }
}

export default defaultStateFactory => ({
  RESET_STORE(state) {
    const _defaultState = defaultStateFactory()
    Object.keys(_defaultState).forEach(key => {
      state[key] = _defaultState[key]
    })
  },

  SET_ENTITIES(state, list) {
    state.entities = list
  },

  SET_SELECTED_ENTITIES(state, list) {
    state.selectedEntities = list
  },

  ADD_SELECTED_ENTITY(state, id) {
    state.selectedEntities.push(id)
  },

  REMOVE_SELECTED_ENTITY(state, index) {
    const selectedEntities = [...state.selectedEntities]

    selectedEntities.splice(index, 1)

    state.selectedEntities = selectedEntities
  },

  REMOVE_FIELD_OPTION(state, id) {
    const fieldOptions = [...state.fieldOptions]

    const index = fieldOptions.findIndex(option => option.entityId === id)
    fieldOptions.splice(index, 1)

    state.fieldOptions = fieldOptions
  },

  RESET_FIELD_OPTION(state) {
      state.fieldOptions = []
  },

  ADD_SELECTED_FIELD(state, payload) {
    const { optionIndex, fieldIndex } = findSelectedFieldIndices(state, payload)
    const { fields } = state.fieldOptions[optionIndex]

    fields[fieldIndex].selected = true
    fields[fieldIndex].deselectable = payload.deselectable ?? true

    state.fieldOptions[optionIndex].fields = fields.sort((a, b) => b.selected - a.selected)
  },

  SELECT_ALL_FIELD_OPTIONS(state, entityId) {
    const { option, index } = findOptionIndex(state, entityId)
    state.fieldOptions[index].fields = option.fields
      .map(field => ({ ...field, selected: true }))
  },

  DESELECT_ALL_FIELD_OPTIONS(state, entityId) {
    const { option, index } = findOptionIndex(state, entityId)
    state.fieldOptions[index].fields = option.fields
      .map(field => ({ ...field, selected: field.deselectable ? false : field.selected }))
  },

  UPDATE_FIELD_FILTERING_STATE(state, payload) {
    const { optionIndex, fieldIndex } = findSelectedFieldIndices(state, payload)
    state.fieldOptions[optionIndex].fields[fieldIndex].filtering = payload.isFiltering
  },

  TOGGLE_OPTION_COLLAPSED_STATE(state, entityId) {
    const { option, index } = findOptionIndex(state, entityId)
    state.fieldOptions[index].collapsed = !option.collapsed
  },

  REMOVE_SELECTED_FIELD(state, payload) {
    const { optionIndex, fieldIndex } = findSelectedFieldIndices(state, payload)
    const { fields } = state.fieldOptions[optionIndex]

    fields[fieldIndex].selected = false

    state.fieldOptions[optionIndex].fields = fields.sort((a, b) => b.selected - a.selected)
  },

  SET_FILTER_VALUE(state, payload) {
    const { optionIndex, fieldIndex } = findSelectedFieldIndices(state, payload)
    state.fieldOptions[optionIndex].fields[fieldIndex].filterValue = payload.filterValue
  },

  SET_FILTER_CONDITION(state, payload) {
    const { optionIndex, fieldIndex } = findSelectedFieldIndices(state, payload)
    state.fieldOptions[optionIndex].fields[fieldIndex].filterCondition = payload.filterCondition
  },

  SET_ERRORS(state, errors) {
    state.errors = errors
  },

  SET_RESULT(state, result) {
    state.result = result
  },
})
