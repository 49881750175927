import { defaultStateFactory } from '@/store/form/state'
import { FieldType } from '@/constants/form'
import prozessEntitySelectorMutation from '@/store/shared/prozessEntitySelectorMutation'

export default {
  ...prozessEntitySelectorMutation(defaultStateFactory),

  ADD_FIELD_OPTION(state, entityId) {
    const entity = state.entities.find(_entity => _entity.id === entityId)
    state.fieldOptions.push({
      entityId,
      collapsed: true,
      schema: entity.schema,
      key: entity.key,
      tableLabel: entity.label,
      fields: entity.columns.map(column => ({
        ...column,
        entitySchema: entity.schema,
        entityKey: entity.key,
        selected: false,
        deselectable: true,
      })),
    })
  },

  SET_BANNER(state, banner) {
    state.banner = banner
  },

  SET_FORM(state, form) {
    state.form = form
  },

  SET_FIELD_INPUTS(state, fieldInputs) {
    state.fieldInputs = fieldInputs
  },

  SET_LAST_SAVED_FIELD_INPUTS(state, lastSavedFieldInputs) {
    state.lastSavedFieldInputs = lastSavedFieldInputs
  },

  SET_THANKYOU_FORM(state, thankYouForm) {
    state.thankYouForm = thankYouForm
  },

  SET_RESOURCE(state, resource) {
    state.resource = resource
  },

  SET_REMOVE_BANNER_FLAG(state, payload) {
    state.removeBannerImage = payload
  },

  UNLINK_FIELDS(state) {
    const { fieldInputs } = state

    state.fieldInputs = fieldInputs.reduce((accumulator, item) => {
      if (item.fieldType === FieldType.QUESTION) {
        item.mergeWith = null
        accumulator.push(item)
      }

      return accumulator
    }, [])
  },
}
