export default {
  Date: 'DATE',
  DateTime: 'DATETIME',
  Email: 'EMAIL',
  Number: 'NUMBER',
  Percent: 'PERCENT',
  Phone: 'PHONE',
  List: 'LIST',
  MultiSelect: 'MULTISELECT',
  Text: 'TEXT',
  LongText: 'LONGTEXT',
  Bool: 'BOOL',
  Url: 'URL',
  Query: 'QUERY',
}

export const customFieldTypeIcons = {
  DATE: 'CalendarIcon',
  DATETIME: 'ClockIcon',
  EMAIL: 'MailIcon',
  NUMBER: 'HashIcon',
  PERCENT: 'PercentIcon',
  PHONE: 'PhoneIcon',
  LIST: 'ListIcon',
  MULTISELECT: 'ListIcon',
  TEXT: 'TypeIcon',
  UUID: 'TypeIcon',
  LONGTEXT: 'AlignLeftIcon',
  BOOL: 'ToggleLeftIcon',
  URL: 'LinkIcon',
  QUERY: 'SearchIcon',
  MAPPING: 'ArrowRightIcon',
}

export const inputType = {
  text: 'text',
  uuid: 'text',
  longtext: 'text',
  url: 'text',
  query: 'text',
  phone: 'number',
  number: 'number',
  percent: 'number',
}

export const customFieldValidations = yup => ({
  TEXT: () => yup.string().nullable().max(255, 'Max 255 limit'),
  LONGTEXT: () => yup.string().nullable(),
  PHONE: () => yup.string().nullable().max(255, 'Max 255 limit'),
  URL: () => yup.string()
    .nullable()
    .max(255, 'Max 255 limit')
    .matches(
      /((https?):\/\/)?(www.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
      'Must be a valid URLes',
    ),
  EMAIL: () => yup.string().email().nullable().max(255, 'Max 255 limit'),
  NUMBER: () => yup.number().nullable().typeError('Required').max(9999999999, 'Max limit 9,999,999,999'),
  PERCENT: () => yup.number().nullable().typeError('Must be a number'),
  DATE: () => yup.date().nullable().transform(v => (v instanceof Date && !isNaN(v) ? v : null)),
  LIST: () => yup.string().nullable().transform(v => (v === null ? '' : v)),

  MULTISELECT: (options = {}) => yup.lazy(value => {
    let validation = Array.isArray(value) ? yup.array() : yup.string()
    validation = validation.nullable().transform(v => (v === null ? '' : v))
    return options?.required ? validation.required() : validation
  }),

  BOOL: () => yup.boolean().nullable(),
  QUERY: () => yup.string().nullable(),
})

export const dateTimeFields = ['received_date', 'due_date']
