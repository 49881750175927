<template>
  <div
    class="prozess-field-wrapper"
    :class="{ 'pz-field-wrapper--disabled': disabled }"
  >
    <b-input-group
      class="input-group-merge form-sidebar__add-option"
      :class="{
        'mb-2': !hint,
      }"
    >
      <b-input-group-prepend
        v-if="icon"
        is-text
      >
        <feather-icon :icon="icon" />
      </b-input-group-prepend>

      <slot />

      <span
        v-if="error && !hint"
        class="invalid-feedback"
      >
        {{ $t(error) }}
      </span>
    </b-input-group>
    <div
      v-if="hint"
      class="d-flex justify-content-between mb-2"
    >
      <span
        v-show="error"
        class="invalid-feedback"
        style="flex: 1"
      >
        {{ $t(error) }}
      </span>
      <span
        style="flex: 2"
        class="field-hint"
      >
        {{ $t(hint) }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/form-sidebar.scss';

.prozess-field-wrapper {
  &.pz-field-wrapper--disabled {
    .input-group-prepend {
      opacity: 0.5;
    }
  }
}
</style>
