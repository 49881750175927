<template>
  <span>{{ innerValue }}</span>
</template>

<script>
import CustomFieldTypes from '@/constants/customFieldTypes'

export default {
  props: {
    type: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number, Array, Object, Boolean],
      default: null,
    },
  },
  computed: {
    innerValue() {
      if (!this.value) return '⠀'

      if (!this.type) return this.value

      switch (this.type) {
        case CustomFieldTypes.List:
          return this.value
        case CustomFieldTypes.Date:
          return this.$d(new Date(this.value), 'short')
        case CustomFieldTypes.Number:
          return this.$n(this.value)
        case CustomFieldTypes.Percent:
          return this.$n(this.value * 100)
        default:
          return this.value
      }
    },

  },
}
</script>
