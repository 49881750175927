import Vue from 'vue'
import BootstrapVue, { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueClipboard from 'vue-clipboard2'
import infiniteScroll from 'vue-infinite-scroll'
import { i18n } from '@/libs/i18n'
import VueSweetalert2 from 'vue-sweetalert2'
import './@core/scss/vue/libs/vue-sweetalert.scss'
import { abilitiesPlugin } from '@casl/vue'
import { register as registerGlobalMixins } from '@/global-mixins'
import { register as registerVueMq } from '@/plugins/vue-mq'
import ability from './libs/acl/ability'
import router from './router'
import store from './store'
import App from './App.vue'
import userAndPermissions from './plugins/userAndPermissions'
import formPlugin from './plugins/form'
import roundPlugin from './plugins/round'
import historyPlugin from './plugins/history'
import VueOffline from 'vue-offline'
import VueMasonry from 'vue-masonry-css'

// Global Components
import './global-components'

// Global Variables
import './global-variables'

import '@/assets/scss/root-variables.scss'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-toastification/dist/index.css'
import ProzessFileUpload from './@core/components/ProzessFileUpload.vue'
import ProzessInput from './@core/components/ProzessInput.vue'
import ProzessSearchDropdown from './@core/components/ProzessSearchDropdown.vue'
import ProzessSearchInput from './@core/components/ProzessSearchInput.vue'
import ProzessFieldWrapper from './@core/components/ProzessFieldWrapper.vue'
import ProzessResourceView from './@core/components/ProzessResourceView.vue'
import ProzessResourceHistory from './@core/components/ProzessResourceHistory.vue'
import ProzessCustomFieldData from './@core/components/ProzessCustomFieldData.vue'
import ProzessSelect from './@core/components/ProzessSelect.vue'
import ProzessResourceValue from './@core/components/ProzessResourceValue.vue'
import ProzessResourceName from './@core/components/ProzessResourceName.vue'
import ProzessDatePicker from './@core/components/ProzessDatePicker.vue'
import ProzessTextarea from './@core/components/ProzessTextarea.vue'

registerGlobalMixins()
registerVueMq()

Vue.use(abilitiesPlugin, ability, {
  useGlobalProperties: true,
})

Vue.use(userAndPermissions)
Vue.use(formPlugin)
Vue.use(historyPlugin)
Vue.use(roundPlugin)
Vue.use(infiniteScroll)
Vue.use(VueClipboard)
Vue.use(VueOffline)
Vue.use(VueSweetalert2)
Vue.use(VueMasonry)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.use(BootstrapVue)

Vue.directive('ripple', Ripple)

Vue.component('ProzessFileUpload', ProzessFileUpload)
Vue.component('ProzessInput', ProzessInput)
Vue.component('ProzessSearchInput', ProzessSearchInput)
Vue.component('ProzessSearchDropdown', ProzessSearchDropdown)
Vue.component('ProzessFieldWrapper', ProzessFieldWrapper)
Vue.component('ProzessResourceView', ProzessResourceView)
Vue.component('ProzessResourceHistory', ProzessResourceHistory)
Vue.component('ProzessCustomFieldData', ProzessCustomFieldData)
Vue.component('ProzessSelect', ProzessSelect)
Vue.component('ProzessResourceValue', ProzessResourceValue)
Vue.component('ProzessResourceName', ProzessResourceName)
Vue.component('ProzessDatePicker', ProzessDatePicker)
Vue.component('ProzessTextarea', ProzessTextarea)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
